import React from 'react';
import { Navbar, Nav, NavDropdown} from 'react-bootstrap'

class Navadmission extends React.Component {

    CleanStorage (){
        localStorage.clear();
    }

    render() {
      return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <NavDropdown title="Resultados" id="basic-nav-dropdown">
                        <NavDropdown.Item href="results">Registro biométrico</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="resultsim">Simulacro</NavDropdown.Item>
                        <NavDropdown.Item href="resultsexam">Examen</NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="changedate">Datos admisión</Nav.Link>
                    <Nav.Link href="create">Crear admisión</Nav.Link>
                    <Nav.Link href="createuser">Crear usuario</Nav.Link>
                </Nav>
                <Nav className="justify-content-end" onClick={this.CleanStorage}>
                    <Nav.Link href="/admision/admin">Cerrar sesión</Nav.Link>
                </Nav>
                
                <Navbar.Brand href="results">
                <img
                    src={require("./../static/logoudeablanco.svg")}
                    width="200"
                    height="80"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
            </Navbar.Brand>
            </Navbar.Collapse>
        </Navbar>
      );
    }
}
  
export default Navadmission