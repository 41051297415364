import React from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {Table, Container, Row, Col, Modal, Form, Button, Tooltip, OverlayTrigger} from 'react-bootstrap'
import Navadmission from './nav';
const time_refreshToken = 600000

var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class ResultsAdm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            careers:{},
            career:"",
            groups:{},
            group:"",
            id_search:"",
            popup:"",

            show_result:false,
            validated:false,
            validated_ID:false,
            validated_popup:false,

            state:true,

            show_column1:false,
            show_column2:false,
            show_column3:false,
            show_video:false,
            text_video:"",
            show_popup:false,
            show_change_status:false,
            id_number_status:"",
            name_status:"",
            old_status:"",
            new_status:"",
            description_status:"",

            url_foto: null,
            url_id: null,
            url_video:null,
            results:[],
            leave_token:false,

        }
        this.search_ID = this.search_ID.bind(this);
        this.change_status = this.change_status.bind(this);
        this.leave = this.leave.bind(this);

    }

    componentDidMount() {
        clearInterval(this.clock_duration) // clean clock to refresh token

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))
        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
            'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                console.log(res.data["tokenSecurity"])
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);

                var body = new FormData();
                body.append("id_admission",localStorage.getItem('id_admission'))
                const config = { headers: { 'Content-Type': 'multipart/form-dat',
                                'Authorization':res.data["tokenSecurity"]}}
                axios.post('https://biometria-api.udea.edu.co/admissionExam/Results/listCarriers',
                body,
                config)
                .then(res => {
                    // Create items array
                    var items = Object.keys(res.data).map(function(key) {
                        return [key, res.data[key]];
                    });
                    
                    // Sort the array based on the second element
                    items.sort(function(first, second) {
                        return first[1] - second[1];
                    });
                    
                    // Create a new array with only the first 5 items
                    let dict = {}; // Convert list to dict
                    for (let i=0; i< items.length; i++ ) {
                        if (items[i][0] !== 'Preregistro'){
                            dict[items[i][0]] = items[i][1];
                        }
                    }
                    this.setState({careers:dict})
                },
                (err)=>{
                    console.log("Tenemos un error", err)
                })
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }
    
    timer_token(){ // Timer for refresh token
        console.log("refresh token")
        clearInterval(this.clock_duration)

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))

        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
            'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }

    RefreshToken = token => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log("--------Token Refresh-----")
    }

    leave(){
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        console.log("--------Token ELIMINADO-----")
        this.props.history.push('/admision/admin')
      }

    handleChangeCareer=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value, show_result:false, validated:false, group:""})

        var body = new FormData();
        body.append("code_udea",value) 
        const config = { headers: { 'Content-Type': 'multipart/form-dat'}}
        axios.post('https://biometria-api.udea.edu.co/admissionExam/Results/listGroups',
        body,
        config)
        .then(res => {
            var items = Object.keys(res.data).map(function(key) {
                return [key, res.data[key]];
            });
            
            // Sort the array based on the second element
            items.sort(function(first, second) {
                return first[1] - second[1];
            });
            
            // Create a new array with only the first 5 items
            let dict = {}; // Convert list to dict
            for (let i=0; i< items.length; i++ ) {
                dict[items[i][0]] = items[i][1];
            }
            this.setState({groups:dict})

        },
        (err)=>{
            console.log("Tenemos un error", err)
        })
    }

    handleChange=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    handlenotChange=(event)=> {
    }


    showResults = (event) => { 
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("invalid form");
        }
        this.setState({validated: true})

        if(form.checkValidity()){

            var body = new FormData();
            body.append("id_group",this.state.group)
            const config = { headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/resultsComparisonRegister",
              body,
              config).then(res => { 
                console.log(res.data)
                this.setState({show_result:true,results:res.data})
              }
              ,(err)=>{
                console.log("Tenemos un error", err)
              })

        }
   }

    search_ID() { // Activar buscar por ID
        this.setState({state:!this.state.state,career:"", groups:{} ,group:"", id_search:"", validated:false, validated_ID:false, results:[], show_result:false})
    }

    showResultsperID = (event) => { 
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("invalid form");
        }
        this.setState({validated_ID: true})

        if(form.checkValidity()){
            console.log(this.state.id_search)
            
            var body = new FormData();
            body.append("id_number",this.state.id_search)
            body.append("id_admission",localStorage.getItem('id_admission'))
            const config = { headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/resultsComparisonRegisterByID",
              body,
              config).then(res => { 
                console.log(res.data)
                if(res.data.length>0){
                    this.setState({show_result:true,results:res.data})
                }
                else{
                    this.setState({popup:"Doc. identidad no encontrado", show_popup:true, show_result:false,results:[]})
                }
              }
              ,(err)=>{
                console.log("Tenemos un error", err)
              })
              
        }
   }

   show_change_status=(index)=> { // Popup change status 
    this.setState({
        show_change_status:true,
        id_number_status:this.state.results[index]["idNumber"],
        name_status:this.state.results[index]["name"],
        old_status:this.state.results[index]["status"]
    })
    }
   

    change_status=(event)=> { // change status 
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("invalid form");
        }
        this.setState({validated_popup: true})
        if(form.checkValidity()){
            
            var body = new FormData();
            body.append("email_privileged",localStorage.getItem('email_user_admission'))
            body.append("id_admission",localStorage.getItem('id_admission'))
            body.append("id_number",this.state.id_number_status)
            body.append("id_group",this.state.group)
            body.append("status",this.state.new_status)
            body.append("description",this.state.description_status)

            const config = { headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/changeStatus",
              body,
              config).then(res => { 
                console.log(res.data)
                this.handleClosePopup()
                if(res.data){
                    this.setState({popup:"Estado cambiado satisfactoriamente", show_popup:true})
                    var body = new FormData(); //Consultar nuevamente los resultados para mostrar el estado actualizado
                    if(this.state.state){
                        body.append("id_group",this.state.group)
                        const config = { headers: {'Content-Type': 'multipart/form-dat',}}
                        axios.post(
                        "https://biometria-api.udea.edu.co/admissionExam/Results/resultsComparisonRegister",
                        body,
                        config).then(res => { 
                            console.log(res.data)
                            this.setState({results:res.data})
                        }
                        ,(err)=>{console.log("Tenemos un error", err)})
                    }
                    else{
                        body.append("id_number",this.state.id_search)
                        body.append("id_admission",localStorage.getItem('id_admission'))
                        const config = { headers: {'Content-Type': 'multipart/form-dat',}}
                        axios.post(
                            "https://biometria-api.udea.edu.co/admissionExam/Results/resultsComparisonRegisterByID",
                            body,
                            config).then(res => { 
                            console.log(res.data)
                                this.setState({results:res.data})
                            }
                            ,(err)=>{
                            console.log("Tenemos un error", err)
                            })
                    }
                }
                else{
                    this.setState({popup:"No fue posible cambiar el estado", show_popup:true})}
              }
              ,(err)=>{
                console.log("Tenemos un error", err)
              })
        }
    }

   export_results = () => {
    var base = "https://biometria-api.udea.edu.co/admissionExam/Results/exportAllCandidates/"
    var dir_url = base.concat(localStorage.getItem('id_admission'))
    //window.open(dir_url, '_blank');

    axios.get(dir_url,
        { responseType: 'blob' })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            console.log(res.data)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }


    handleClosePopup = () => {
        this.setState({show_column1:false,show_column2:false,
            show_column3:false,show_popup:false,
            show_change_status:false,id_number_status:"",
            description_status:"",new_status:"",validated_popup:false,
            show_video:false, text_video:""});
      }

    column1=(index)=> {
        this.setState({show_column1:true, url_foto: null, url_id: null,})
        var base_photos = "https://biometria-api.udea.edu.co/admissionExam/Results/security/loadPhoto/"

        // Peticion foto documento
        var url_idPhotoDocument = base_photos.concat((this.state.results[index]["idPhotoDocument"]).toString())
        var config = { responseType: 'blob' }
        axios.get(url_idPhotoDocument,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_id: obj})
            })

        // Peticion foto actual
        var url_idPhotoPersonal = base_photos.concat((this.state.results[index]["idPhotoPersonal"]).toString())
        axios.get(url_idPhotoPersonal,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_foto: obj})
            })
    }

    column2=(index)=> {
        this.setState({show_column2:true, url_video: null, url_id: null,
             text_video: this.state.results[index]["idModel"]===null? "Modelo biométrico no realizado":"Cargando..."})

        // Peticion foto ID
        var base_photos = "https://biometria-api.udea.edu.co/admissionExam/Results/security/loadPhoto/"
        var url_idPhotoDocument = base_photos.concat((this.state.results[index]["idPhotoDocument"]).toString())
        var config = { responseType: 'blob' }
        axios.get(url_idPhotoDocument,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_id: obj})
            })

        // Peticion video biometrico
        var base_videos = "https://biometria-api.udea.edu.co/admissionExam/Results/loadVideo/"
        var url_video_mon = base_videos.concat(this.state.results[index]["idModel"]===null ? "":this.state.results[index]["idModel"].toString())
        axios.get(url_video_mon,
        config)
        .then(res => {
          console.log(res)
          const obj = URL.createObjectURL(res.data)
          this.setState({ url_video: obj, video_select:index, show_video:true})
        })

    }

    column3=(index)=> {
        this.setState({show_column3:true, url_video: null, url_foto: null,
            text_video: this.state.results[index]["idModel"]===null? "Modelo biométrico no realizado":"Cargando..."})

        // Peticion foto actual
        var base_photos = "https://biometria-api.udea.edu.co/admissionExam/Results/security/loadPhoto/"
        var url_idPhotoPersonal = base_photos.concat((this.state.results[index]["idPhotoPersonal"]).toString())
        var config = { responseType: 'blob' }
        axios.get(url_idPhotoPersonal,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_foto: obj})
            })

        // Peticion video biometrico
        var base_videos = "https://biometria-api.udea.edu.co/admissionExam/Results/loadVideo/"
        var url_video_mon = base_videos.concat(this.state.results[index]["idModel"]===null ? "":this.state.results[index]["idModel"].toString())
        axios.get(url_video_mon,
        config)
        .then(res => {
          console.log(res)
          const obj = URL.createObjectURL(res.data)
          this.setState({ url_video: obj, video_select:index, show_video:true})
        })
        
        
    }

    renderTable =(result,index) => {
        return(
            <tr key={index}>
                <td>{result["idNumber"]}</td>
                <td>{result["name"]}</td>
                <td>
                    <button className="button_image" onClick={() => this.column1(index)}>
                        <img
                        src={require(result["documentVsPersonalFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td>   
                <td>
                    <button className="button_image" onClick={() => this.column2(index)}>
                        <img
                        src={require(result["documentVsModelFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td> 

                <td>          
                    <button className="button_image" onClick={() => this.column3(index)}>
                        <img
                        src={require(result["personalVsModelFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td>

                <td>          
                    <img
                    src={require(result["symEvaludea"] ? "./../static/success.png":"./../static/failure.png")}
                    width="35"
                    height="35"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </td>

                <td>
                    <Button variant="link" onClick={() => this.show_change_status(index)}>{result["status"]}</Button>
                </td>

            </tr>
        )
    }

    renderTableID =(result,index) => {
        return(
            <tr key={index}>
                <td>{result["idNumber"]}</td>
                <td>{result["name"]}</td>
                <td>{result["nameCarrier"]}</td>
                <td>
                    <button className="button_image" onClick={() => this.column1(index)}>
                        <img
                        src={require(result["documentVsPersonalFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td>   
                <td>
                    <button className="button_image" onClick={() => this.column2(index)}>
                        <img
                        src={require(result["documentVsModelFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td> 

                <td>          
                    <button className="button_image" onClick={() => this.column3(index)}>
                        <img
                        src={require(result["personalVsModelFlag"] ? "./../static/success.png":"./../static/failure.png")}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </td>
                
                <td>          
                    <img
                    src={require(result["symEvaludea"] ? "./../static/success.png":"./../static/failure.png")}
                    width="35"
                    height="35"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </td>

                <td>
                    {result["status"]}
                </td>
            </tr>
        )
    }

    renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Buscar usuario por ID
        </Tooltip>
      );

render() {
    return (
      <Container>
        <Navadmission />
        <div className="text-center pt-3">
        <h2><b>Resultados registros examen de admisión UdeA</b></h2>
        </div>
        <hr />

        {/* Resultados generales */}
        {this.state.state &&<Row>
            <Col md={4}>
                <Form.Group as={Row}>
                    <Form.Label column md={3}>
                        Carrera:
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control 
                            required
                            as="select"  
                            name="career" 
                            value={this.state.career}
                            onChange={this.handleChangeCareer}>
                            <option value="" disabled>Elija...</option>
                            {Object.entries(this.state.careers).map( ([key, value]) => {
                            return <option key={key} value={value}>{key}</option>;
                        })}
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Col>

            <Col md={6}>
                <Form noValidate validated={this.state.validated} onSubmit={e => this.showResults(e)}>
                    <Form.Group as={Row}>
                        <Form.Label column md={2}>
                            Grupo:
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control 
                                required
                                as="select"  
                                name="group" 
                                value={this.state.group}
                                onChange={this.handleChange}>
                                <option value="" disabled>Elija...</option>
                                {Object.entries(this.state.groups).map( ([key, value]) => {
                                return <option key={key} value={value}>{key}</option>;
                            })}
                            </Form.Control>
                        </Col>
                        
                        <Col md={6}>
                            <Row>
                            <Button variant="success mr-1" type="submit">
                                    Mostrar
                            </Button>
                            &nbsp;&nbsp;&nbsp;
                            <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderTooltip}>
                                <button type= "button" className="button_image" onClick={this.search_ID}>
                                    <img
                                    src={require("./../static/search2.png")}
                                    width="35"
                                    height="35"
                                    alt="React Bootstrap logo"
                                    />
                                </button>
                             </OverlayTrigger>
                            </Row>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>
        </Row>}

        {/* Resultados por ID */}
        {!this.state.state &&<Row>
            <Col md={12}>
                <Form noValidate validated={this.state.validated_ID} onSubmit={e => this.showResultsperID(e)}>
                    <Form.Group as={Row}>
                        <Form.Label column md={2}>
                            Doc. identidad:
                        </Form.Label>
                        <Col md={3}>
                            <Form.Control
                                required
                                name="id_search"
                                type="text"
                                value={this.state.id_search} 
                                onChange={this.handleChange}
                            />
                        </Col>                        
                        <Col md={5}>
                            <Row>
                            <Button variant="success mr-1" type="submit">
                                    Buscar
                            </Button>
                            <Button variant="danger mr-1" onClick={this.search_ID}>
                                    Volver
                            </Button>
                            </Row>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>
        </Row>}


        <br />
        {/* Tabla Resultados Generales*/}
        {this.state.show_result && this.state.state &&<Table striped bordered>
            <thead>
                <tr>
                    <th>Doc. Identidad</th>
                    <th>Nombre</th>
                    <th>Foto vs. Id</th>
                    <th>Id vs. Modelo</th>
                    <th>Foto vs. Modelo</th>
                    <th>Simulacro</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                {this.state.results.map(this.renderTable)}
            </tbody>
        </Table>}

        {/* Tabla Resultados por ID */}
        {this.state.show_result && !this.state.state &&<Table striped bordered>
            <thead>
                <tr>
                    <th>Doc. Identidad</th>
                    <th>Nombre</th>
                    <th>Programa</th>
                    <th>Foto vs. Id</th>
                    <th>Id vs. Modelo</th>
                    <th>Foto vs. Modelo</th>
                    <th>Simulacro</th>
                    <th>Estado</th>
                </tr>
            </thead>
            <tbody>
                {this.state.results.map(this.renderTableID)}
            </tbody>
        </Table>}


        <br />

        <center>
            {(this.state.career === "" && this.state.state) && <Button
                variant="primary mr-1" 
                onClick={this.export_results}>
                Exportar resultados generales
            </Button>}
        </center>

        <Modal size="lg" show={this.state.show_column1} onHide={this.handleClosePopup}>
            <Row>
                <Col align="center" className="modal_background_photo">
                    <div>
                    <img src={this.state.url_foto}
                        width= "100%"
                        height= "100%"
                        alt="Cargando..."/>
                    </div>
                </Col>
                <Col align="center" className="modal_background_photo">
                    <img src={this.state.url_id}
                        width= "100%"
                        height= "100%"
                        alt="Cargando..."/>
                </Col>
            </Row>
        </Modal>

        {/* Column2: id vs. modelo */}
        <Modal size="lg" show={this.state.show_column2} onHide={this.handleClosePopup}>
        <Row>
            <Col align="center" className="modal_background_photo">
                <img src={this.state.url_id}
                    width= "100%"
                    height= "100%"
                    alt="Cargando..."/>
            </Col>
            {this.state.show_video&&<Col className="modal_background">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe 
                        title="Embeds Page"
                        allowFullScreen
                        allow="autoplay"
                        className="embed-responsive-item"
                        controls="controls"
                        src={this.state.url_video}/>
                </div>
            </Col>}
            {!this.state.show_video&&<Col className="modal_background">
                    <p style={{color:"white"}}>{this.state.text_video}</p>
            </Col>}

        </Row>
        </Modal>

        {/* Column3: actual vs. modelo */}
        <Modal size="lg" show={this.state.show_column3} onHide={this.handleClosePopup}>
        <Row>
            <Col align="center" className="modal_background_photo">
                <img src={this.state.url_foto}
                    width= "100%"
                    height= "100%"
                    alt="Cargando..."/>
            </Col>
            {this.state.show_video && <Col className="modal_background">
                <div className="embed-responsive embed-responsive-16by9">
                    <iframe 
                        title="Embeds Page"
                        allowFullScreen
                        allow="autoplay"
                        className="embed-responsive-item"
                        controls="controls"
                        src={this.state.url_video}/>
                </div>
            </Col>}
            {!this.state.show_video&&<Col className="modal_background">
                    <p style={{color:"white"}}>{this.state.text_video}</p>
            </Col>}
        </Row>
        </Modal>

        {/* Popup */}
        <Modal show={this.state.show_popup} onHide={this.handleClosePopup}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClosePopup}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

        {/* Popup change status*/}
        <Modal size="lg" show={this.state.show_change_status} onHide={this.handleClosePopup}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Cambiar estado
            </Modal.Title>
            </Modal.Header>
 
            <Form noValidate validated={this.state.validated_popup} onSubmit={e => this.change_status(e)}>
                <Modal.Body>

                <Form.Group as={Row}>

                    <Form.Label column md={3}>
                        <b>Nombre:</b>
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            required
                            name="name_status"
                            type="text"
                            onChange={this.handlenotChange}
                            value={this.state.name_status} 
                        />
                    </Col>
                    <Form.Label column md={3}>
                        <b>Doc. identidad:</b>
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            required
                            name="id_number_status"
                            type="text"
                            onChange={this.handlenotChange}
                            value={this.state.id_number_status} 
                        />
                    </Col>
                    <Form.Label column md={3}>
                        <b>Estado actual:</b>
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            required
                            name="old_status"
                            type="text"
                            onChange={this.handlenotChange}
                            value={this.state.old_status==="PEN" ? ("Pendiente") : 
                            (this.state.old_status==="VIR" ? ("Virtual"): 
                            (this.state.old_status==="PRE" ? ("Presencial"):"")
                            )}
                        />
                    </Col>

                    <Form.Label column md={3}>
                        <b>Nuevo estado:</b>
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control 
                            required
                            as="select"  
                            name="new_status" 
                            value={this.state.new_status}
                            onChange={this.handleChange}>
                            <option value="" disabled>Elija...</option>
                            <option value="VIR">Virtual</option>
                            <option value="PRE">Presencial</option>
                        </Form.Control>
                    </Col>

                    <Form.Label column md={3}>
                        <b>Justificación:</b>
                    </Form.Label>
                    <Col md={9}>
                        <Form.Control
                            required
                            name="description_status"
                            as="textarea"
                            pattern="[0-9A-zÀ-ž\s]{1,500}$"
                            rows="4"
                            value={this.state.description_status} 
                            onChange={this.handleChange}
                        />
                        <Form.Control.Feedback type="invalid">El número mínimo de caracteres es 5 y máximo 500 (no introduzca caracteres especiales)</Form.Control.Feedback>
                    </Col>
                </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger mr-1" onClick={this.handleClosePopup}>
                            Cancelar
                    </Button>
                    <Button variant="primary mr-1" type="submit">
                            Cambiar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

        {/* Popup leave invalid token */}
        <Modal show={this.state.leave_token} onHide={this.leave}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">No fue posible refrescar el token, por favor ingrese nuevamente</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.leave}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

    </Container>
    )}

    

}


export default withRouter(ResultsAdm)