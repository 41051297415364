import React from 'react';
import { withRouter } from 'react-router-dom';


class WebcamStream extends React.Component {
    constructor(props) {
        super(props);
        this.videoTag = React.createRef()
        this.state = {
            recording: false,
            audios: [],
            url_video: null,
            videoSrc:null,
            blob_video:null,
            task:"Ayer fui al médico. ¿Qué le pasa? Me preguntó. Yo le dije: ¡Ay doctor! Donde pongo el dedo me duele. ¿Tiene la uña rota? Sí. Pues ya sabemos qué es. Deje su cheque a la salida.",
          };
    }

    componentDidMount() {
       navigator.mediaDevices
            .getUserMedia({video: true, audio: true})
            .then(stream => {
                var options = {
                  audioBitsPerSecond: 128000,
                  videoBitsPerSecond: 2500000,
                  mimeType: 'video/webm', //https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
                }
                this.videoTag.current.srcObject = stream;
                this.mediaRecorder = new MediaRecorder(stream,options);
                // init data storage for video chunks
                this.chunks = [];
                // listen for data from media recorder
                this.mediaRecorder.ondataavailable = e => {
                  if (e.data && e.data.size > 0) {
                    this.chunks.push(e.data);                    
                  }
                };
            }
            )
            .catch(console.log);
    }

    startRecording(e) {
        e.preventDefault();
        // wipe old data chunks
        this.chunks = [];
        // start recorder with 10ms buffer
        this.mediaRecorder.start(10);
        console.log(this.mediaRecorder.mimeType)
        // say that we're recording
        this.setState({recording: true});
    }

    stopRecording(e) {
        e.preventDefault();
        // stop the recorder
        this.mediaRecorder.stop();
        // say that we're not recording
        this.setState({recording: false});
        // save the video to memory
        this.saveAudio();
    }

    saveAudio() {
        // convert saved chunks to blob
        const blob = new Blob(this.chunks, {type : "video/webm"});

        console.log("Blob",blob)
        console.log("Chunks", this.chunks)
        // generate video url from blob
        const audioURL = URL.createObjectURL(blob);
        console.log("audioURL",audioURL)
        //console.log(audioURL)
        //console.log(this.mediaRecorder.mediaBlobUrl)
        // append videoURL to list of saved videos for rendering
        //const audios = this.state.audios.concat([audioURL]);
        this.setState({url_video:audioURL,blob_video:blob});
    }


    render() {
        const {recording, url_video, task} = this.state;

        return (
            <div>
              <center>
                <h3>{task}</h3>
              </center>
                <div className="row">
                    <div className="column">
                        <video
                            muted
                            id={this.props.id}
                            ref={this.videoTag}
                            audio="false"
                            width={300}
                            height={300}
                            autoPlay
                            title={this.props.title}>
                            controls
                        </video>   
                    </div>
                    <div className="column">
                        <video 
                            controls="controls"
                            width={300}
                            height={300}
                            src={url_video}
                        />
                    </div>
                </div>
                <div className="center">
                        {!recording && <button onClick={e => this.startRecording(e)}>Record</button>}
                        {recording && <button onClick={e => this.stopRecording(e)}>Stop</button>}
                        <br />
                        {/*!recording && <button onClick={e => this.sendRecording(e)}>Send</button>}
                        { Boton para Eliminar grabaciones
                        <button onClick={() => this.deleteAudio(audios.URL)}>Delete</button>
        }             */}
                </div>

            </div> 


        )}
}


export default withRouter(WebcamStream)