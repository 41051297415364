import React from 'react'
import { withRouter } from 'react-router-dom';
import {Form, Button, Row, Col, Container, Modal} from 'react-bootstrap'
import Navadmin from './nav';
import axios from 'axios';
import DatePicker,{ registerLocale } from "react-datepicker"
import { es } from 'date-fns/locale'
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);

const time_refreshToken = 600000
var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class ChangeDates extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            id_admision: "",
            validated: false,
            active_admision: false,
            dateFinishBioregister: "",
            dateFinishSimulacrum: "",
            dateRememberExam: "",
            dateSendResults: "",
            dateStartBioregister: "",
            dateStartExam: "",
            dateStartSimulacrum: "",
            numberStudents: 0,
            msn_popup:"",
            justification:"",
            show_popup:false,
            leave_token:false,
        }
        this.handleChangeSBioregister = this.handleChangeSBioregister.bind(this);
        this.handleChangeFBioregister = this.handleChangeFBioregister.bind(this);
        this.handleChangeSSimulacrum = this.handleChangeSSimulacrum.bind(this);
        this.handleChangeFSimulacrum = this.handleChangeFSimulacrum.bind(this);
        this.handleChangeRememberExam = this.handleChangeRememberExam.bind(this);
        this.handleChangeSExam = this.handleChangeSExam.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangecert = this.handleChangecert.bind(this);


        this.leave = this.leave.bind(this);
        this.CastingDate = this.CastingDate.bind(this);
        this.CastingDateHour = this.CastingDateHour.bind(this);
      }


      componentDidMount() {
        this.setState({id_admision: localStorage.getItem("id_admission")})

        clearInterval(this.clock_duration) // clean clock to refresh token
        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))
        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
            'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                console.log(res.data["tokenSecurity"])
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);

                var url_get='https://biometria-api.udea.edu.co/admissionExam/Administration/checkAdmission/'
                var url_final = url_get.concat(localStorage.getItem('id_admission'))

                const config = {headers: {'Authorization':res.data["tokenSecurity"]}}
                axios.get(url_final,
                config) 
                .then(res => {
                  console.log(res.data)
                  this.setState({
                    active_admision: res.data["active"],
                    dateFinishBioregister: this.CastingDate(res.data["dateFinishBioregister"]),
                    dateFinishSimulacrum: this.CastingDate(res.data["dateFinishSimulacrum"]),
                    dateRememberExam: this.CastingDate(res.data["dateRememberExam"]),
                    dateSendResults: this.CastingDate(res.data["dateSendResults"]),
                    dateStartBioregister: this.CastingDate(res.data["dateStartBioregister"]),
                    dateStartExam: this.CastingDateHour(res.data["dateStartExam"]),
                    dateStartSimulacrum: this.CastingDate(res.data["dateStartSimulacrum"]),
                    numberStudents: res.data["numberStudents"]
                    })
                })
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }
    
    timer_token(){ // Timer for refresh token
        console.log("refresh token")
        clearInterval(this.clock_duration)

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))

        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }


    CastingDate = date_string => {
        var date = ""
        if (typeof date_string === 'string' && date_string.length===10 && date_string!=="2119-01-01"){
            var year = parseInt(date_string.split("-")[0])
            var month = parseInt(date_string.split("-")[1])-1 // es necesario restar uno ya que en JS comienza desde el mes 0
            var day = parseInt(date_string.split("-")[2])
            date = new Date(year,month,day)
        }
        return date
    }

    CastingDateHour = date => {
        var date_final = ""
        if (typeof date === 'string' && date.length===19 && date!=="2119-01-01 00:00:00"){

            let date_string = date.split(" ")[0]
            let date_hour = date.split(" ")[1]

            var year = parseInt(date_string.split("-")[0])
            var month = parseInt(date_string.split("-")[1])-1 // es necesario restar uno ya que en JS comienza desde el mes 0
            var day = parseInt(date_string.split("-")[2])

            var hour = parseInt(date_hour.split(":")[0])
            var min = parseInt(date_hour.split(":")[1])
            var seg = parseInt(date_hour.split(":")[2])

            date_final = new Date(year,month,day, hour, min, seg)
        }
        return date_final
    }

    RefreshToken = token => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log("--------Token Refresh-----")
    }

    leave(){
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        console.log("--------Token ELIMINADO-----")
        this.props.history.push('/admision/admin')
      }

      handleClose = () => {
        this.setState({validated:false, show_popup:false, justification:""});
    }

    handleChange=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    handleChangeSBioregister = date => {
        this.setState({ dateStartBioregister: date!==null?date:""});
    };

    handleChangeFBioregister = date => {
        this.setState({ dateFinishBioregister: date!==null?date:""});
    };

    handleChangeSSimulacrum = date => {
        this.setState({ dateStartSimulacrum: date!==null?date:""});
    };

    handleChangeFSimulacrum = date => {
        this.setState({ dateFinishSimulacrum: date!==null?date:""});
    };

    handleChangeRememberExam = date => {
        this.setState({ dateRememberExam: date!==null?date:""});
    };

    handleChangeSExam = date => {
        this.setState({ dateStartExam: date!==null?date:""});
    };

    handleChangeSendResults = date => {
        this.setState({ dateSendResults: date!==null?date:""});
    };

    handleChangecert=(event)=> {
        this.setState({ active_admision: event.target.checked });
      }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        console.log("Admisión activada: ", (this.state.active_admision))
        console.log("Comienzo RB: ", this.state.dateStartBioregister)
        console.log("Finalización RB: ", this.state.dateFinishBioregister)
        console.log("Comienzo simulacro: ", this.state.dateStartSimulacrum)
        console.log("Finalización simulacro: ", this.state.dateFinishSimulacrum)
        console.log("Recordar examen: ", this.state.dateRememberExam)
        console.log("Presentación examen: ", this.state.dateStartExam)
        console.log("Envio resultados: ", this.state.dateSendResults)
        console.log("Numero de estudiantes: ", this.state.numberStudents)

        const form = event.currentTarget;
        this.setState({validated: true})

        
        if(form.checkValidity()){
            
            var body = new FormData();
            body.append("id_admission", this.state.id_admision)
            body.append("max_students", this.state.numberStudents)
            body.append("start_bioRegister", this.state.dateStartBioregister===""?"":this.state.dateStartBioregister.toJSON().split("T")[0])
            body.append("finish_bioRegister", this.state.dateFinishBioregister===""?"":this.state.dateFinishBioregister.toJSON().split("T")[0])
            body.append("start_simulacrum", this.state.dateStartSimulacrum===""?"":this.state.dateStartSimulacrum.toJSON().split("T")[0])
            body.append("finish_simulacrum", this.state.dateFinishSimulacrum===""?"":this.state.dateFinishSimulacrum.toJSON().split("T")[0])
            body.append("remember_Exam", this.state.dateRememberExam===""?"":this.state.dateRememberExam.toJSON().split("T")[0])
            body.append("send_results", this.state.dateSendResults===""?"":this.state.dateSendResults.toJSON().split("T")[0])
            if(this.state.dateStartExam !== ""){
                let string_date = this.state.dateStartExam.toJSON().split("T")[0]
                let hour_date = (this.state.dateStartExam.toLocaleString('en-GB')).split(",")[1]
                body.append("start_exam", string_date.concat(hour_date))
            }
            else{
                body.append("start_exam", "")
            }
            body.append("is_active", this.state.active_admision)

            const config = {headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Administration/updateAdmission",
            body,
            config).then(res => { 
                this.setState({show_popup:true,msn_popup:"Datos almacenados exitosamente"})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
                if(err.request.status===400){
                    this.setState({show_popup:true,msn_popup:"No fue posible modificar la fecha, por favor intente nuevamente o contacte al administrador"})
                }
            })
        }
        
    }

    render() {
        return (
            <Container>
                <Navadmin />
                <div className="text-center pt-3">
                    <h2><b>Información admisión {localStorage.getItem('id_admission')}</b></h2>
                </div>
                <hr />
                <div className="text-center pt-1">
                    <p>A continuación asigne o modifique los datos asociados a la admisión {localStorage.getItem('id_admission')}</p>
                </div>
                <Form noValidate validated={this.state.validated} onSubmit={e => this.handleSubmit(e)}>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Comienzo registro biometrico: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateStartBioregister" 
                                        selected={this.state.dateStartBioregister}
                                        onChange={this.handleChangeSBioregister}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Finalización registro biometrico: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateFinishBioregister" 
                                        selected={this.state.dateFinishBioregister}
                                        onChange={this.handleChangeFBioregister}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Comienzo simulacro: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateStartSimulacrum" 
                                        selected={this.state.dateStartSimulacrum}
                                        onChange={this.handleChangeSSimulacrum}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Finalización simulacro: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateFinishSimulacrum" 
                                        selected={this.state.dateFinishSimulacrum}
                                        onChange={this.handleChangeFSimulacrum}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Recordatorio del examen: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateRememberExam" 
                                        selected={this.state.dateRememberExam}
                                        onChange={this.handleChangeRememberExam}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Presentación del examen: 
                                </Form.Label>
                                    <DatePicker 
                                        showTimeSelect
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy h:mm aa"
                                        name="dateStartExam" 
                                        selected={this.state.dateStartExam}
                                        onChange={this.handleChangeSExam}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label column md={6}>
                                    Envio de resultados: 
                                </Form.Label>
                                    <DatePicker 
                                        locale="es"
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        name="dateSendResults" 
                                        selected={this.state.dateSendResults}
                                        onChange={this.handleChangeSendResults}
                                        placeholderText="dd/mm/aaaa"
                                    />
                            </Form.Group>
                        </Col>
                        <Col/>
                    </Row>

                        <Col  sm={11}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Num. aspirantes por grupo: 
                                </Form.Label >
                                <Col sm={2}>
                                    <Form.Control
                                        name="numberStudents" 
                                        type="number"
                                        value={this.state.numberStudents} 
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col/>

                        <Col>
                            <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Admisión activa: 
                                </Form.Label >
                                <Col>
                                <Form.Check 
                                    aria-label="option 1" 
                                    name="active_admision" 
                                    checked={this.state.active_admision}
                                    onChange={this.handleChangecert}
                                />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col/>
                    <br />
                    <center>
                        <Button variant="success mr-1" type="submit">
                            Modificar
                        </Button>
                    </center>
                </Form>

                {/* Mensaje final */}
                <Modal show={this.state.show_popup} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{this.state.msn_popup}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

                {/* Popup leave invalid token */}
                <Modal show={this.state.leave_token} onHide={this.leave}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">No fue posible refrescar el token, por favor ingrese nuevamente</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.leave}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

            </Container>


    )}

}


export default withRouter(ChangeDates)