import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import './../style.css';
import {Button, Container, Row, Col, Modal, ProgressBar} from 'react-bootstrap'

var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class CaptureAdmRegister extends React.Component {
  constructor(props) {
      super(props);
      this.videoTag = React.createRef()
      this.canvasRef = React.createRef()
      this.prev_state = null
      this.localStream = null

      this.state = {
        url_youtube:"",
        flag_time:false,
        number_task:0,
        pos_task:0,
        uploadPercentage: 0,
        recording: false,
        recorded: false,
        url_video: null,
        videoSrc:null,
        minutes:0,
        seconds:0,
        blob_video:null,
        flag_send:false,
        permissions:false,
        showpermissions:false,
        info_send:"Enviando video",
        showpopup:false,
        show_popup:false,
        show_popup_inicial:false,
        show_popup_inicial2:false,
        show_popup_video_final:false,
        popup:"",
        task:""
      };
      this.nextTask = this.nextTask.bind(this);
      this.leave = this.leave.bind(this);
      this.popupleave = this.popupleave.bind(this);
      this.analyzeStream = this.analyzeStream.bind(this);
      this.RefreshToken = this.RefreshToken.bind(this);
  }

  componentDidMount() {
    
     console.log("Estado anterior",this.props.location.state)
     this.prev_state = this.props.location.state
    
     this.setState({task:this.prev_state["allowTaskAdmission"]["taskDescription"][this.state.pos_task],
                  number_task:this.prev_state["allowTaskAdmission"]["numberTask"]})

      if (this.prev_state["allowTaskAdmission"]["urlTask"][this.state.pos_task] !== ""){
        var embed = "https://www.youtube.com/embed/"+this.youtube_parser(this.prev_state["allowTaskAdmission"]["urlTask"][this.state.pos_task])
        this.setState({url_youtube:embed})
      }
      else{
        this.setState({url_youtube:""})
      }
      
     navigator.mediaDevices.getUserMedia({video: {width: 640, height: 480}, audio: {channelCount:1}})
          .then(stream => {
              var options = {
                audioBitsPerSecond: 128000,
                videoBitsPerSecond: 2500000,
                mimeType: 'video/webm;codecs=vp8,opus', //https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
              }
              this.videoTag.current.srcObject = stream;
              this.mediaRecorder = new MediaRecorder(stream,options);
              this.localStream = stream;
              // init data storage for video chunks
              
              this.chunks = [];
              // listen for data from media recorder
              this.mediaRecorder.ondataavailable = e => {
                if (e.data && e.data.size > 0) {
                  this.chunks.push(e.data);                    
                }
              };
              this.analyzeStream()
              this.setState({permissions:true, show_popup_inicial:true}) 
          }
          )
          .catch(function (err) {
            console.log("error : " + err)
          });
  }

    RefreshToken = token => {
      axios.defaults.headers.common['Authorization'] = token;
      console.log("--------Token Refresh-----")
  }

  
  analyzeStream() {
 
    var audioCtx = new AudioContext();
    var audioInput = audioCtx.createMediaStreamSource(this.localStream);
    var energyProcessor = audioCtx.createScriptProcessor(4096,1,1);

    const canvas = this.canvasRef.current
    const canvasContext = canvas.getContext('2d')
  
    var saturatedSamples = 0;
  
    energyProcessor.onaudioprocess = function(audioProcessingEvent) {
      var inputBuffer = audioProcessingEvent.inputBuffer;
      for (var channel = 0; channel < inputBuffer.numberOfChannels; channel++) {
        var inputData = inputBuffer.getChannelData(channel);
        //var energy = 0;
        var maxabs = 0;
        for (var sample = 0; sample < inputBuffer.length; sample++) {
          // make output equal to the same as the input
          //energy+=inputData[sample]*inputData[sample];
          var absSample = Math.abs(inputData[sample]);
          if (absSample > maxabs){
            maxabs = absSample;
          }
          if (absSample > 0.99) {
            saturatedSamples++;
            maxabs=0.999
            console.log(maxabs)

          }
          if (maxabs>0.95) {
            canvasContext.fillStyle="red";
          }
          else{
            canvasContext.fillStyle="blue";
          }
        };
        if (saturatedSamples>= 300){//audioInput.connect(audioCtx.destination);
          saturatedSamples = 0;
          alert('El audio está demasiado fuerte! Por favor baja el volumen del micrófono.');
        }
        var width=300
        var height=25
        canvasContext.clearRect(0, 0, width, height);
        canvasContext.fillRect(0, 0,(Math.round(maxabs*width)),height);
        canvasContext.stroke();

      }
    }
    audioInput.connect(energyProcessor);
    energyProcessor.connect(audioCtx.destination);
  }


  nextTask(){
    if(this.state.pos_task+1 === this.state.number_task){
      delete axios.defaults.headers.common['Authorization'];
      console.log("--------Token ELIMINADO-----")
      this.props.history.push('/admision/final')
      var tracks = this.localStream.getTracks();
      tracks.forEach(function(track){
        track.stop();
      });
    }
    else{
    window.scroll({top:0,behavior:'smooth'})
    if (this.prev_state["allowTaskAdmission"]["urlTask"][this.state.pos_task+1] !== ""){
      var embed = "https://www.youtube.com/embed/"+this.youtube_parser(this.prev_state["allowTaskAdmission"]["urlTask"][this.state.pos_task+1])
      this.setState({url_youtube:embed})
    }
    else{
      this.setState({url_youtube:""})
    }
    this.setState({task:this.prev_state["allowTaskAdmission"]["taskDescription"][this.state.pos_task+1],
      recording: false,
      recorded: false,
      url_video: null,
      videoSrc:null,
      flag_time:false,
      blob_video:null,
      uploadPercentage:0,
      flag_send: false,
      minutes:0,
      seconds:0,
      info_send:"Enviando video"
    })
    this.setState((state, props) => ({
      pos_task: state.pos_task + 1
    }));
    }
  }

  leave(){
    clearInterval(this.chronometer)
    clearInterval(this.clock_durationout)
    clearInterval(this.clock_duration)
    delete axios.defaults.headers.common['Authorization'];
    console.log("--------Token ELIMINADO-----")
    this.props.history.push('/admision')
    if (this.state.permissions){
      var tracks = this.localStream.getTracks();
      tracks.forEach(function(track){
        track.stop();
      });
    }
  }

  popupleave(){
    this.setState({showpopup:true})
  }

  handleCloseInitial = () => {
    this.setState({show_popup_inicial:false, show_popup_inicial2:true});
}

  handleClose = () => {
    this.setState({showpopup:false, showpermissions:false, show_popup:false, show_popup_inicial2:false, show_popup_video_final:false});
}

ShoWPopup_video_final = () => {
  this.setState({show_popup_video_final:true});
}

  showHelp(e) {
    this.setState({showpermissions:true});
  }

  youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length===11)? match[7] : false;
  }

  timer_duration(){
    this.setState({flag_time:true})
    clearInterval(this.clock_duration)
  }

  time_out_duration(){
    this.stopRecording()
  }

  timer_chronometer(){
    const { seconds, minutes } = this.state
      this.setState(({ seconds }) => ({
          seconds: seconds + 1
      }))
      if (seconds >= 59) {
        this.setState({seconds:0})
        this.setState(({ minutes }) => ({
          minutes: minutes + 1
        }))
      }
      if (minutes > 59) {
        this.setState({seconds:0,minutes:0})
      }
  }

  startRecording(e) {
      e.preventDefault();
      // wipe old data chunks
      this.chunks = [];
      // start recorder with 10ms buffer
      this.mediaRecorder.start(10);
      // say that we're recording
      this.setState({recording: true});
      this.clock_duration = setInterval(this.timer_duration.bind(this), this.prev_state["allowTaskAdmission"]["min_rec"][this.state.pos_task]);
      this.clock_durationout = setInterval(this.time_out_duration.bind(this), this.prev_state["allowTaskAdmission"]["max_rec"][this.state.pos_task]);
      this.chronometer = setInterval(this.timer_chronometer.bind(this), 1000); // cada seg

  }

  stopRecording(e) {
      clearInterval(this.clock_durationout)
      //e.preventDefault();
      // stop the recorder
      this.mediaRecorder.stop();
      // say that we're not recording
      this.setState({recording: false});
      // save the video to memory
      this.saveAudio();
      clearInterval(this.chronometer)
  }

  saveAudio() {
      // convert saved chunks to blob
      const blob = new Blob(this.chunks, {type : "video/webm"});
      //const blob = new Blob(this.chunks);
      // generate video url from blob
      const audioURL = URL.createObjectURL(blob);
      // append videoURL to list of saved videos for rendering
      this.setState({url_video:audioURL,blob_video:blob, recorded:true});
  }

  restartAudio() {
    // filter out current videoURL from the list of saved videos
    this.setState({recording: false, recorded: false, url_video: null, videoSrc:null, flag_time:false,
       blob_video:null, uploadPercentage:0, flag_send: false, info_send:"Enviando video",seconds:0,minutes:0});
  }

  sendRecording(e) {
    this.setState({flag_send: true, show_popup_video_final:false})
    let formdata = new FormData()
    formdata.append("id_number",this.prev_state["id_number"])
    formdata.append("file",this.state.blob_video,'task.webm')
    formdata.append("name_task",this.prev_state["allowTaskAdmission"]["nameTask"][this.state.pos_task])

    if(this.state.pos_task+1 === this.state.number_task){
      formdata.append("train_complete",true)
    }
    else{
      formdata.append("train_complete",false)
    }

    const config = {headers: {'Content-Type': 'multipart/form-dat',},
      onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent
        let percent = Math.floor( (loaded * 100) / total)
        console.log(`${loaded}kb of ${total}kb | ${percent}%`)
        if( percent < 100 ){
          this.setState({ uploadPercentage: percent })
        }
      }
    };

    axios.post(
      "https://biometria-api.udea.edu.co/admissionExam/BioRegister/traincapture",
      formdata,
      config).then(res => { 
        console.log(res)
        this.setState({uploadPercentage: 100, info_send: "Video enviado", flag_send: false})
      },(err)=>{
        console.log("Tenemos un error", err)
        if (err.message==="Network Error"){ //Refresh token
          console.log("Refresh token")
          // Token request
          var instance_refresh = axios.create(); //create new instance for send info without token
          delete instance_refresh.defaults.headers.common['Authorization'];
          var body_refresh = new FormData();
          body_refresh.append("id_number",this.prev_state["id_number"])
          body_refresh.append("password",this.prev_state["password"])
          const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                      'Token-Security':static_token}
          };
          instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/BioRegister/login",
              body_refresh, config_ref).then(res => { 
              console.log(res.data)
              this.RefreshToken(res.data["token_security"])
              // New post with requested token
              const config = { headers: { 'Content-Type': 'multipart/form-dat',}};
              axios.post(
                  "https://biometria-api.udea.edu.co/admissionExam/BioRegister/traincapture",
                  formdata, config)
                  .then(res => { 
                    console.log(res.data)
                    this.setState({uploadPercentage: 100, info_send: "Video enviado", flag_send: false})
                  },(err)=>{
                    console.log("Tenemos un error", err)
                    this.setState({popup:"Problemas con el envío de videos, por favor verifique su conexión e ingrese nuevamente", show_popup:true})
                  })
            },(err)=>{
              console.log("Tenemos un error", err)
              this.setState({popup:"Problemas con el envío de videos, por favor verifique su conexión e ingrese nuevamente", show_popup:true})
            })
         }
         else{
          this.setState({popup:"Problemas con el envío de videos, por favor verifique su conexión e ingrese nuevamente", show_popup_correos:true, next:false})
         }
      })
  }

  
  render() {
      const {recording, url_video, task, permissions, recorded, uploadPercentage, info_send, flag_send,pos_task,number_task,url_youtube, flag_time} = this.state;

      return (
          <Container>
            <div className="cinta-superior">
              <img
                src={require("./../static/logoudea.svg")}
                width="320"
                height="100"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </div>
              <div className="text-center pt-3">
                <h2><b>Registro biométrico: Prueba {pos_task+1}/{number_task}</b></h2>
              </div>
            <hr />
            <div>
              {url_youtube!==""&&<Row>
                <Col xs={12} md={8}>
                  <h3><b>Instrucciones:</b></h3>
                  <ol>
                    <li>Presione el botón <b>Grabar</b> cuando estés listo(a).</li>
                    <li><b>{task}</b></li>
                    <li>Para finalizar, presiona el botón <b>Detener</b> y, si consideras que
                     la captura ha quedado de manera correcta, presione el botón <b>Enviar</b>.</li>
                    <li>Presiona <b>Siguiente</b> para continuar con la prueba.</li>

                  </ol> 
                </Col>
                <Col xs={6} md={4}>
                  <div className="embed-responsive embed-responsive-16by9">
                    <iframe 
                        title="Embeds Page"
                        className="embed-responsive-item"
                        controls="controls"
                        src={url_youtube}/>
                  </div>
                </Col>
              </Row>}

              {url_youtube===""&&<div>
                <h3><b>Instrucciones:</b></h3>
                <ol>
                  <li>Presione el botón <b>Grabar</b> cuando estés listo(a).</li>
                  <li><b>{task}</b></li>
                  <li>Para finalizar, presiona el botón <b>Detener</b> y, si consideras que
                     la captura ha quedado de manera correcta, presione el botón <b>Enviar</b>.</li>
                  <li>Presiona <b>Siguiente</b> para continuar con la prueba.</li>
                </ol> 
              </div>}
              <br/>
              {!permissions && <div>
                  <center>
                    <h3>Si te aparece este anuncio es porque no has dado permisos para la
                      captura de la cámara y el micrófono. El siguiente botón te brinda
                      información sobre cómo solucionar este problema.</h3>
                    <Button
                        variant="danger"
                        size="lg"
                        onClick={e => this.showHelp(e)}>
                        Ayuda
                    </Button>
                  </center>
                  <br />
              </div>}
            </div>
              <Row>
                <Col center-block="true">
                  <div className="embed-responsive embed-responsive-16by9">
                    <video
                      muted
                      id={this.props.id}
                      ref={this.videoTag}
                      audio="false"
                      width="200"
                      height="200"
                      autoPlay
                      title={this.props.title}>
                      controls muted
                    </video> 
                  </div>  
                </Col>

              <Col center-block="true">
                <div className="embed-responsive embed-responsive-16by9">
                    {recorded && <video 
                        controls="controls"
                        width={200}
                        height={200}
                        src={url_video}
                    >
                    </video>}
                </div>  
              </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <canvas className="sty_canvas" ref={this.canvasRef} width="300" height="25"/>
                </Col>
                <Col/>

              </Row>
              {uploadPercentage > 0 && 
              <p className="text-center"><b>{info_send}</b></p>}
              {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active="true" label={`${uploadPercentage}%`} /> }
              {uploadPercentage > 0 && <br />}
              <Row>
                {<Button variant="primary mr-1" disabled={recording || recorded || !permissions} onClick={e => this.startRecording(e)}>GRABAR</Button>}
                {<Button variant="primary mr-1" disabled={!recording || !flag_time} onClick={e => this.stopRecording(e)}>DETENER</Button>}
                {recorded && <Button variant="primary mr-1" disabled={uploadPercentage===100 || flag_send} onClick={e => this.restartAudio(e)}>REINICIAR</Button>}
              {(recording || recorded) && <p className="chronometer">{this.state.minutes}:{this.state.seconds<10?"0"+this.state.seconds:this.state.seconds}</p>
              }
              </Row>
              <br />
              <Row>
                {<Button
                  variant="success mr-1"
                  disabled={!recorded || !permissions ||  flag_send || uploadPercentage===100}
                  onClick={ (pos_task+1===number_task) ? this.ShoWPopup_video_final : e => this.sendRecording(e)}>
                  ENVIAR
                </Button>}
              </Row>
              <br />
              <Row>
                <Col className="button-left">
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={this.popupleave}>
                    Salir
                </Button>
                </Col>
                <Col/>
                <Col/>
                <Col className="button-right">
                  <Button
                    variant="success"
                    size="lg"
                    disabled={uploadPercentage!==100}
                    onClick={this.nextTask}>
                    Siguiente {pos_task+1}/{number_task}
                </Button>
                </Col>
              </Row>
              <br />
              <br />


              {/* Popup mensaje envío video final */}
              <Modal show={this.state.show_popup_video_final} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Finalización del registro biométrico
                  </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <p>Estás a punto de finalizar el registro biométrico. Si deseas continuar
                      con el proceso, haz clic en el botón <b>Aceptar</b>. Si no te encuentras seguro(a)
                      de presentar el examen de admisión en modalidad virtual, puedes cancelar y salir
                      del registro biométrico.</p> 
                      <p>Recuerda que la realización del registro biométrico es un requisito
                      obligatorio para la presentación de la prueba, por lo tanto, si no lo
                      realizas no podrás presentarla.</p>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="danger" onClick={this.handleClose}>
                      Cancelar
                  </Button>
                  <Button variant="primary" onClick={e => this.sendRecording(e)}>
                      Aceptar
                  </Button>
                  </Modal.Footer>
              </Modal>

              {/* Popup mensajes */}
              <Modal show={this.state.show_popup} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
                  </Modal.Header>
                  <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Salir
                  </Button>
                  </Modal.Footer>
              </Modal>

              {/* Popup inicial*/}
              <Modal size="lg" show={this.state.show_popup_inicial}>
              <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Políticas de tratamiento de información personal
              </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                En virtud del artículo 8 de la ley 1581 de 2013, el titular de la
                información personal que es recopilada en este registro biométrico, tiene
                los siguientes derechos:
                <ul>
                    <li>Conocer, actualizar, rectificar y suprimir los datos suministrados.</li>
                    <li>Conocer los usos que se han hecho de la información suministrada,
                        cuando así lo solicite el titular.</li>
                    <li>Revocar la autorización y/o solicitar la supresión del dato suministrado
                        cuando en el tratamiento realizado no se respeten los principios,
                        derechos y garantías constitucionales y legales a favor del titular.</li>
                    <li>Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.</li>
                    <li>El titular de la información suministrada, podrá ejercer cualquiera
                        de los derechos mencionados, dirigiendo una petición en este sentido
                        a la dirección electrónica examenvirtual@udea.edu.co.
                        Recibida la solicitud en los términos dispuestos por la Universidad,
                        se le dará trámite al requerimiento según lo establecido por la ley.</li>
                </ul>

                Al oprimir el botón <b>Acepto</b> esta indicando que esta
                de acuerdo en la captura de voz y rostro, al igual que las políticas
                de tratamiento de información personal.
              </Modal.Body>
              <Modal.Footer>
              <Button variant="danger" onClick={this.leave}>
                  No acepto
              </Button>
              <Button variant="success" onClick={this.handleCloseInitial}>
                  Acepto
              </Button>
              </Modal.Footer>
          </Modal>



              {/* Popup inicial2*/}
              <Modal size="lg" show={this.state.show_popup_inicial2} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Instrucciones para la toma biométrica
                  </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <p>El registro biométrico consiste en una serie de actividades que
                        le permiten a la Universidad la creación del perfil para validar
                        la participación a lo largo del examen de admisión en modalidad
                        virtual.</p>
                      <p>A continuación, se solicita el desarrollo de tres actividades
                        diferentes: una captura de rostro, la lectura de un texto y un
                        discurso de 30 segundos sobre ti. Asegúrate de que otras plataformas
                        o herramientas no estén haciendo uso de la cámara o micrófono;
                        en caso de que sí, debes cerrarlas.</p>
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Aceptar
                  </Button>
                  </Modal.Footer>
              </Modal>
              
              {/* Popup Salir*/}
              <Modal show={this.state.showpopup} onHide={this.handleClose}>
                  <Modal.Header closeButton>
                  <Modal.Title>¿Está seguro?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      Ten presente que tu progreso no será almacenado y no tendrás modelo biométrico.
                  </Modal.Body>
                  <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Cancelar
                  </Button>
                  <Button variant="danger" onClick={this.leave}>
                      Salir
                  </Button>
                  </Modal.Footer>
              </Modal>

              {/* Popup Permisos*/}
              <Modal size="lg" show={this.state.showpermissions} onHide={this.handleClose}>
                  <Modal.Header>
                  <Modal.Title>Permisos de captura</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <p> 
                  Por favor brinda los permisos a la plataforma de Biometría para la captura del
                  micrófono y la cámara; posterior a esto, actualiza la página con las teclas Ctrl + F5. 
                  Verifica que otras plataformas o herramientas no estén haciendo uso del micrófono
                  o la cámara pues esto puede bloquear el uso. Para más información:
                  </p>
                  <ul>
                    <li> 
                      <a href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=es-419">Ayuda Chrome</a>
                    </li>
                    <li> 
                      <a href="https://support.mozilla.org/es/kb/como-administrar-los-permisos-de-tu-camara-y-micro">Ayuda Firefox</a>
                    </li>
                  </ul>
                  </Modal.Body>
                  <Modal.Footer>
                  </Modal.Footer>
              </Modal>

          </Container> 
      )}
}


export default withRouter(CaptureAdmRegister)