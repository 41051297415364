import React from 'react'
import { withRouter } from 'react-router-dom';
import './../style.css';
import {Button, Container} from 'react-bootstrap'


class FinishAdmRegister extends React.Component {

  constructor(props){
      super(props)
      this.salir = this.salir.bind(this);
    }

   salir = () => {
    this.props.history.push('/admision')
    }

  render() {
    return (
      <Container>
        <div className="cinta-superior">
          <img
            src={require("./../static/logoudea.svg")}
            width="320"
            height="100"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </div>
        <div className="text-center pt-3">
        <h2><b>Registro biométrico finalizado</b></h2>
        </div>
        <hr />
        <p>Estimado(a) aspirante,</p>

        <p align="justify">Has finalizado el proceso de registro biométrico,
        el sistema verificará que la información ingresada en la inscripción
        coincida con el perfil biométrico realizado. En caso de que la información
        se verifique de manera correcta, se te enviará un correo electrónico con
        las indicaciones para la presentación del simulacro del examen virtual.
        Si por el contrario la información ingresada en la inscripción no coincide
        con el perfil biométrico, recibirás un correo notificando esta situación
        y las instrucciones para que descargues la credencial con los datos del
        lugar y fecha en donde debes presentar el examen de manera presencial. </p>

        <p align="justify">Ten presente que estos correos se enviarán una día
        después del cierre del proceso de registro biométrico,
        motivo por el cual deberás estar muy atento a tu correo electrónico.
        Ten en cuenta revisar la bandeja de SPAM.</p>

    
        <p align="justify"><b>Nota</b>. La realización del simulacro del examen es
        obligatoria, ya que de esta manera se podrá verificar el cumplimiento
        de todos los requisitos técnicos asociados a la presentación de un examen
        en modalidad virtual. Si por algún motivo no puedes presentar el simulacro,
        podrás presentar el examen en modalidad presencial en el mismo sitio que
        elegiste como alternativa al momento de diligenciar el formulario de inscripción.</p>

        <p align="justify">Ante cualquier duda o eventualidad, puedes comunicarte al
        correo electrónico examenvirtual@udea.edu.co.</p>
        <p> Agradecemos tu atención </p>
        <center>
        <Button
                variant="success mr-1"
                onClick={this.salir}>
                Salir
              </Button>
        </center>

      </Container>
    )
  }
}


export default withRouter(FinishAdmRegister)