import React from 'react'
import { withRouter } from 'react-router-dom';
import {Form, Button, Row, Col, Container, Modal} from 'react-bootstrap'
import Navadmin from './nav';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

const time_refreshToken = 600000
var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class CreateUser extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            validated: false,
            name_user:"",
            password:"",
            email: "",
            email2: "",
            role: "Administrador",
            code_role: "001",
            msn_popup:"",
            show_popup:false,
            leave_token:false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.leave = this.leave.bind(this);

      }


      componentDidMount() {
        clearInterval(this.clock_duration) // clean clock to refresh token

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))
        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                console.log(res.data["tokenSecurity"])
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }
    
    timer_token(){ // Timer for refresh token
        console.log("refresh token")
        clearInterval(this.clock_duration)

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))

        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }

    RefreshToken = token => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log("--------Token Refresh-----")
    }

    leave(){
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        console.log("--------Token ELIMINADO-----")
        this.props.history.push('/admision/admin')
      }

    handleChange=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    handleClose = () => {
        this.setState({validated:false, name_user:"", password:"", email:"", email2:"", msn_popup:"", show_popup:false});
    }


    handleValidated = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({validated: true})

        if(form.checkValidity()){

            if(this.state.email===this.state.email2){
                console.log("name_user",this.state.name_user)
                console.log("password",this.state.password)
                console.log("email",this.state.email)
                console.log("Rol",this.state.role)
                console.log("code_rol",this.state.code_role)

                var body_upd = new FormData();
                body_upd.append("username", this.state.name_user)
                body_upd.append("password", this.state.password)
                body_upd.append("email", this.state.email)
                body_upd.append("role", this.state.role)
                body_upd.append("code_role", this.state.code_role)
                const config = {headers: {'Content-Type': 'multipart/form-dat',}}
                axios.post(
                    "https://biometria-api.udea.edu.co/admissionExam/general/createSuperUser",
                    body_upd,
                    config).then(res => { 
                        console.log(res.data)
                        if(res.data){
                            this.setState({show_popup:true,msn_popup:"Usuario creado exitosamente"})
                        }
                        else{
                            this.setState({show_popup:true,msn_popup:"No se pudo crear el usuario, por favor verifique los datos"})
                        }
                    }
                    ,(err)=>{
                        console.log("Tenemos un error", err)
                        this.setState({show_popup:true,msn_popup:"Por favor contacte el adminstrador"})
                    })
            }
            else{
                if (this.state.email!==this.state.email2)
                    this.setState({msn_popup:"No coinciden los correos electrónicos", show_popup:true})
            }
        }
    }



    render() {
        return (
            <Container>
                <Navadmin />
                <div className="text-center pt-3">
                    <h2><b>Crear usuario privilegiado</b></h2>
                </div>
                <hr />

                <Form noValidate validated={this.state.validated} onSubmit={e => this.handleValidated(e)}>

                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nombre de usuario:
                                </Form.Label>
                                    <Form.Control
                                        required
                                        name="name_user"
                                        type="text"
                                        value={this.state.name_user} 
                                        onChange={this.handleChange}
                                    />
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Contraseña:
                                </Form.Label>
                                    <Form.Control
                                        required
                                        name="password"
                                        type="password"
                                        value={this.state.password} 
                                        onChange={this.handleChange}
                                    />
                            </Form.Group>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Correo electrónico:
                                </Form.Label>
                                    <Form.Control
                                        required
                                        name="email"
                                        type="email"
                                        value={this.state.email} 
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Ingrese un correo valido</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Repetir correo electrónico:
                                </Form.Label>
                                    <Form.Control
                                        required
                                        name="email2"
                                        type="email"
                                        value={this.state.email2} 
                                        onChange={this.handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">Ingrese un correo valido</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <br />
                    <center>
                        <Button variant="danger mr-1"
                            onClick={this.handleClose}>
                            Limpiar
                        </Button>
                        <Button
                            variant="success mr-1"
                            type="submit">
                            Enviar
                        </Button>
                    </center>
                    <br />
                    <br />
                </Form>


                {/* Mensaje final */}
                <Modal show={this.state.show_popup} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{this.state.msn_popup}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

                {/* Popup leave invalid token */}
                <Modal show={this.state.leave_token} onHide={this.leave}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">No fue posible refrescar el token, por favor ingrese nuevamente</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.leave}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

            </Container>


    )}

}


export default withRouter(CreateUser)