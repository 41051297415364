import React from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import './../style.css';
import {Form, Button, Container, Modal, Col, Row} from 'react-bootstrap'

//var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"


class LoginAdmRegister extends React.Component {

  constructor(props){
      super(props)
      this.state = {
        id_number:"",
        pass:"",
        show_popup:false,
        popup:"",
        validate: true,
        validated: false,
      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
      this.SaveToken = this.SaveToken.bind(this);
    }


  handleChange=(event)=> {
      const {name, value} = event.target
      this.setState({[name]: value})
  }


  SaveToken = token => {
    if (token) {
    //applying token
      axios.defaults.headers.common['Authorization'] = token;
      console.log("--------Token GUARDADO-----")
    } 
    else{
      //deleting the token from header
      delete axios.defaults.headers.common['Authorization'];
      console.log("--------Token ELIMINADO-----")
    }
   }

   handleClose = () => {
    this.setState({show_popup:false});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        console.log("invalid form");
    }
    this.setState({validated: true})

    if(form.checkValidity()){

      this.setState({popup:"Período de registro biométrico finalizado", show_popup:true})   
      
      /*
      
      var body = new FormData();
      body.append("id_number",this.state.id_number)
      body.append("password",this.state.pass)

      const config = { 
        headers: {'Content-Type': 'multipart/form-dat',
                  'Token-Security':static_token}
      };

      axios.post(
        "https://biometria-api.udea.edu.co/admissionExam/BioRegister/login",
        body,
        config).then(res => { 
          if(res.data["candidate_exist"] && res.data["valid_pass"] && !res.data["task_register_complete"] && res.data["admission_valid"]){
            this.SaveToken(res.data["token_security"])
            this.props.history.push({
              pathname:'/admision/captura',
              state:{"id_number":this.state.id_number, "password":this.state.pass, "allowTaskAdmission":res.data["allowTaskAdmission"]},
            })
          }
          else if (res.data["candidate_exist"] && !res.data["valid_pass"] && !res.data["task_register_complete"]) {
            this.setState({popup:"Contraseña invalida", show_popup:true})
          }
          else if (res.data["task_register_complete"] && res.data["candidate_exist"] && res.data["valid_pass"]) {
            this.setState({popup:"Registro biometrico ya realizado", show_popup:true})          
          }
          else if (!res.data["candidate_exist"]){
            this.setState({popup:"Aspirante no registrado", show_popup:true})
          }
          else if (!res.data["admission_valid"]){
              this.setState({popup:"Credenciales invalidas para la admisión actual", show_popup:true})
          }
          else {
            this.setState({popup:"Error: por favor contacte el adminstrador", show_popup:true})
          }
        },(err)=>{
          console.log("Tenemos un error", err)
        })

      */
              
      }

      
        
  
  }

  render() {
    return (
      <Container>
        <div className="cinta-superior">
          <Row>
            <Col>
              <img
                src={require("./../static/logoudea.svg")}
                width="320"
                height="100"
                className="d-inline-block align-top"
                alt="React Bootstrap logo"
              />
            </Col>
            <Col>
              <a className="right" href="/admision/admin">¿Eres administrador?</a>
            </Col>
          </Row>
        </div>
        <div className="text-center pt-3">
          <h2><b>Registro biométrico examen de admisión UdeA</b></h2>
        </div>
        <hr />
        <div className="login-page">
          <div className="form">
            <Form noValidate validated={this.state.validated} onSubmit={e => this.handleSubmit(e)}> 
              <Form.Group>
                <Form.Control 
                  required
                  name="id_number"
                  type="text"
                  value={this.state.id_number} 
                  placeholder="Doc. identidad"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Solo se permiten números (no agregue puntos)</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Control 
                  required
                  name="pass"
                  type="password"
                  pattern="[0-9]{6}"
                  value={this.state.pass} 
                  placeholder="Contraseña"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Debe contener 6 caracteres</Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="success mr-1"
                type="submit">
                Ingresar
              </Button>
            </Form>
          </div>
        </div>

        {/* Mensaje final */}
        <Modal show={this.state.show_popup} onHide={this.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

      </Container>
    )
  }
}


export default withRouter(LoginAdmRegister)