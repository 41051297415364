import React from 'react'
import { withRouter } from 'react-router-dom';
import {Table, Form, Button, Row, Col, Container, Modal} from 'react-bootstrap'
import Navadmin from './nav';
import axios from 'axios';
import DatePicker,{ registerLocale } from "react-datepicker"
import { es } from 'date-fns/locale'
import "react-datepicker/dist/react-datepicker.css";
registerLocale("es", es);

const time_refreshToken = 600000
var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class Addadmission extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            id_admision: "",
            validated: false,
            validated_2:false,
            show_careers:false,
            students:50,

            active_admision: true,
            dateFinishBioregister: "",
            dateFinishSimulacrum: "",
            dateRememberExam: "",
            dateSendResults: "",
            dateStartBioregister: "",
            dateStartExam: "",
            dateStartSimulacrum: "",

            msn_popup:"",
            show_popup:false,
            show_popup_help:false,
            show_popup_dates:false,
            table_careers:[],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeSBioregister = this.handleChangeSBioregister.bind(this);
        this.handleChangeFBioregister = this.handleChangeFBioregister.bind(this);
        this.handleChangeSSimulacrum = this.handleChangeSSimulacrum.bind(this);
        this.handleChangeFSimulacrum = this.handleChangeFSimulacrum.bind(this);
        this.handleChangeRememberExam = this.handleChangeRememberExam.bind(this);
        this.handleChangeSExam = this.handleChangeSExam.bind(this);
        this.handleChangecert = this.handleChangecert.bind(this);
        this.HandleAssingDates = this.HandleAssingDates.bind(this);
        this.leave = this.leave.bind(this);
      }


      componentDidMount() {
        clearInterval(this.clock_duration) // clean clock to refresh token

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))
        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                console.log(res.data["tokenSecurity"])
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }
    
    timer_token(){ // Timer for refresh token
        console.log("refresh token")
        clearInterval(this.clock_duration)

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))

        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }

    RefreshToken = token => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log("--------Token Refresh-----")
    }

    leave(){
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        console.log("--------Token ELIMINADO-----")
        this.props.history.push('/admision/admin')
      }

    handleChange=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
    }

    handleClose = () => {
        this.setState({validated:false, validated_2:false, id_admision:"",
                        show_careers:false, show_popup:false, show_popup_help:false,
                        table_careers:[], students:50, active_admision: true,
                        dateFinishBioregister: "", dateFinishSimulacrum: "",
                        dateRememberExam: "", dateSendResults: "", dateStartBioregister: "",
                        dateStartExam: "", dateStartSimulacrum: "" });
    }

    HandleAssingDates = () => {
        this.setState({show_popup_dates:true});
    }

    handleChangeSBioregister = date => {
        this.setState({ dateStartBioregister: date!==null?date:""});
    };

    handleChangeFBioregister = date => {
        this.setState({ dateFinishBioregister: date!==null?date:""});
    };

    handleChangeSSimulacrum = date => {
        this.setState({ dateStartSimulacrum: date!==null?date:""});
    };

    handleChangeFSimulacrum = date => {
        this.setState({ dateFinishSimulacrum: date!==null?date:""});
    };

    handleChangeRememberExam = date => {
        this.setState({ dateRememberExam: date!==null?date:""});
    };

    handleChangeSExam = date => {
        this.setState({ dateStartExam: date!==null?date:""});
    };

    handleChangeSendResults = date => {
        this.setState({ dateSendResults: date!==null?date:""});
    };

    handleChangecert=(event)=> {
        this.setState({ active_admision: event.target.checked });
    };

    handleCloseHelp = () => {
        this.setState({show_popup_help:false, show_popup_dates:false});
    }

    handleValidated = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({validated: true})

        if(form.checkValidity()){          
            var body_upd = new FormData();
            body_upd.append("id_admission", this.state.id_admision)
            const config = {headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Administration/listCarrers",
            body_upd,
            config).then(res => { 
                console.log(res.data)
                if(res.data["id_admission_available"]){
                    this.setState({table_careers:res.data["carrierInformationList"],show_careers:true, show_popup_help:true})
                }
                else if (!res.data["id_admission_available"]){
                    this.setState({show_popup:true,msn_popup:"ID de admisión no valido."})
                }
                else if (!res.data["exist_biometria"]){
                    this.setState({show_popup:true,msn_popup:"ID de admisión existente."})
                }
                else{
                    this.setState({show_popup:true,msn_popup:"Por favor contacte el adminstrador"})
                }
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
                this.setState({show_popup:true,msn_popup:"Por favor contacte el adminstrador"})
            })
        }
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        this.setState({validated_2: true})

        if(form.checkValidity()){
            
            var body = new FormData();
            body.append("id_admission", this.state.id_admision)
            body.append("max_students", this.state.students)
            body.append("start_bioRegister", this.state.dateStartBioregister===""?"":this.state.dateStartBioregister.toJSON().split("T")[0])
            body.append("finish_bioRegister", this.state.dateFinishBioregister===""?"":this.state.dateFinishBioregister.toJSON().split("T")[0])
            body.append("start_simulacrum", this.state.dateStartSimulacrum===""?"":this.state.dateStartSimulacrum.toJSON().split("T")[0])
            body.append("finish_simulacrum", this.state.dateFinishSimulacrum===""?"":this.state.dateFinishSimulacrum.toJSON().split("T")[0])
            body.append("remember_Exam", this.state.dateRememberExam===""?"":this.state.dateRememberExam.toJSON().split("T")[0])
            body.append("send_results", this.state.dateSendResults===""?"":this.state.dateSendResults.toJSON().split("T")[0])
            if(this.state.dateStartExam !== ""){
                let string_date = this.state.dateStartExam.toJSON().split("T")[0]
                let hour_date = (this.state.dateStartExam.toLocaleString('en-GB')).split(",")[1]
                body.append("start_exam", string_date.concat(hour_date))
            }
            else{ body.append("start_exam", "")}

            const config = {headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Administration/createAdmission",
            body,
            config).then(res => { 
                if (res.data){
                    this.setState({show_popup:true,msn_popup:"ID de admisión creado exitosamente!"})
                }
                else{
                    this.setState({show_popup:true,msn_popup:"El ID de admisión no pudo crearse, por favor intente nuevamente."})
                }
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
                if(err.request.status===400){
                    this.setState({show_popup:true,msn_popup:"El ID de admisión no pudo crearse, por favor intente nuevamente."})
                }
            })
        }
    }

    renderTable =(result,index) => {
        return(
            <tr key={index}>
                <td>{result["programa"]}</td>
                <td>{result["nombre_largo"]}</td>         
            </tr>
        )
    }

    render() {
        return (
            <Container>
                <Navadmin />
                <div className="text-center pt-3">
                    <h2><b>Crear admisión</b></h2>
                </div>
                <hr />
                <Row>
                    <Col sm={5}>
                        <Form noValidate validated={this.state.validated} onSubmit={e => this.handleValidated(e)}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={4}>
                                    ID de admisión:
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                            required
                                            name="id_admision" 
                                            type="text"
                                            disabled={this.state.show_careers}
                                            value={this.state.id_admision} 
                                            onChange={this.handleChange}
                                        />
                                </Col>
                                
                                <Col sm={2}>
                                    <Button variant="primary mr-1" disabled={this.state.show_careers} type="submit">
                                            Consultar
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                <Row>

                <Col sm={5}>
                    {this.state.show_careers && <Form noValidate validated={this.state.validated_2} onSubmit={e => this.handleSubmit(e)}>
                            <Form.Group as={Row}>
                                <Form.Label column sm={4}>
                                    Num. aspirantes:
                                </Form.Label>
                                <Col sm={6}>
                                    <Form.Control
                                            required
                                            name="students" 
                                            type="number"
                                            value={this.state.students} 
                                            onChange={this.handleChange}
                                        />
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Col>
                    <Col sm={7}>
                    {this.state.show_careers && <Form noValidate validated={this.state.validated_2} onSubmit={e => this.handleSubmit(e)}>
                    <Form.Group as={Row}>
                                <Form.Label column sm={3}>
                                    Activar admisión: 
                                </Form.Label >
                                <Col>
                                <Form.Check 
                                    aria-label="option 1" 
                                    name="active_admision" 
                                    checked={this.state.active_admision}
                                    onChange={this.handleChangecert}
                                />
                                </Col>
                                
                                <Col sm={7}>
                                    <Button variant="success mr-1" type="submit">
                                            Crear
                                    </Button>
                                    <Button variant="primary mr-1" onClick={this.HandleAssingDates}>
                                            Asignar fechas
                                    </Button>
                                    <Button variant="danger mr-1"onClick={this.handleClose}>
                                            Cancelar
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>}
                    </Col>


                </Row>

                {this.state.show_careers && <div>
                    <Table striped bordered>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Carrera</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.table_careers.map(this.renderTable)}
                        </tbody>
                    </Table>
                </div>}

                {/* Popup ayuda*/}
                <Modal size="lg" show={this.state.show_popup_help} onHide={this.handleCloseHelp}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      ID de admisión valido
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Por favor valide el código y el nombre de las carreras asignadas al ID de admisión
                         ingresado; ademas, ingrese el numero de candidatos que formarán cada grupo
                          biométrico (el valor por defecto es 50). Finalmente, puede ingresar a la opción
                          Asignar fechas para fijar las fechas relacionadas a la admisión.
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCloseHelp}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

                {/* Popup Asignar fechas*/}
                <Modal size="xl" show={this.state.show_popup_dates} onHide={this.handleCloseHelp}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Asignación de fechas
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Comienzo registro biometrico: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateStartBioregister" 
                                            selected={this.state.dateStartBioregister}
                                            onChange={this.handleChangeSBioregister}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Finalización registro biometrico: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateFinishBioregister" 
                                            selected={this.state.dateFinishBioregister}
                                            onChange={this.handleChangeFBioregister}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Comienzo simulacro: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateStartSimulacrum" 
                                            selected={this.state.dateStartSimulacrum}
                                            onChange={this.handleChangeSSimulacrum}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Finalización simulacro: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateFinishSimulacrum" 
                                            selected={this.state.dateFinishSimulacrum}
                                            onChange={this.handleChangeFSimulacrum}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Recordatorio del examen: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateRememberExam" 
                                            selected={this.state.dateRememberExam}
                                            onChange={this.handleChangeRememberExam}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Presentación del examen: 
                                    </Form.Label>
                                        <DatePicker 
                                            showTimeSelect
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy h:mm aa"
                                            name="dateStartExam" 
                                            selected={this.state.dateStartExam}
                                            onChange={this.handleChangeSExam}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label column md={6}>
                                        Envio de resultados: 
                                    </Form.Label>
                                        <DatePicker 
                                            locale="es"
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            name="dateSendResults" 
                                            selected={this.state.dateSendResults}
                                            onChange={this.handleChangeSendResults}
                                            placeholderText="dd/mm/aaaa"
                                        />
                                </Form.Group>
                            </Col>
                            <Col/>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCloseHelp}>
                        Cerrar
                    </Button>
                    </Modal.Footer>
                </Modal>


                {/* Mensaje final */}
                <Modal show={this.state.show_popup} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{this.state.msn_popup}</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.handleClose}>
                        Salir
                    </Button>
                    </Modal.Footer>
                </Modal>

            </Container>


    )}

}


export default withRouter(Addadmission)