import React from 'react';
import { Route, Redirect } from "react-router-dom";
import axios from 'axios';


const GuardedUserAdmission = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        axios.defaults.headers.common['Authorization']
            ? <Component {...props} />
            : <Redirect to='/admision' />
    )} />
)

export default GuardedUserAdmission;