import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import {Container, Row, Col, ProgressBar, Button} from 'react-bootstrap'


class RecordTestAdm extends React.Component {
    constructor(props) {
      super(props);
      this.videoTag = React.createRef()
      this.prev_state = null
      this.localStream = null
      this.state = {
          time_start: 5000, //5seg
          time_recording: 10000, //10seg
          time_interval: 20000, //20seg
          time_again: 5000, //5seg
          uploadPercentage: 0,
          recorded: false,
          count:0,
          mediaRecorder: null,
          url_video: null,
          videoSrc:null,
          blob_video:null,
          chunks:[],
          state:"Preparado",
      };

      this.startRecording = this.startRecording.bind(this)
      this.stopRecording = this.stopRecording.bind(this)  
      this.saveAudio = this.saveAudio.bind(this)  
      this.restartAudio = this.restartAudio.bind(this)  
      this.sendRecording = this.sendRecording.bind(this)  
      this.exit = this.exit.bind(this)  
    }

    componentDidMount() {
        // getting access to webcam
       this.prev_state = this.props.location.state
       console.log("Previous state",this.prev_state)
       this.setState({time_start:this.prev_state["startRecording"]*1000,
                     time_interval:this.prev_state["intervalRecording"]*1000})
       navigator.mediaDevices
            .getUserMedia({video: {width: 640, height: 480}, audio: {channelCount:1}})
            .then(stream => {
                var options = {
                  audioBitsPerSecond: 128000,
                  videoBitsPerSecond: 2500000, //https://github.com/w3c/mediacapture-record/issues/194 problemas con la codificación
                  mimeType: 'video/webm;codecs=vp8,opus', //https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
                }

                this.videoTag.current.srcObject = stream;
                this.localStream = stream;
                this.mediaRecorder = new MediaRecorder(stream,options);

                this.intervalId = setInterval(this.timer_start.bind(this), this.state.time_start);

                // init data storage for video chunks
                this.chunks = [];
                // listen for data from media recorder
                this.mediaRecorder.ondataavailable = e => {
                  if (e.data && e.data.size > 0) {
                    this.chunks.push(e.data);
                  }
                };
            }
            )
            .catch(function (err) {
              console.log("error : " + err)
              alert('No se pudo acceder a la cámara o el micrófono, por favor brinde los permisos para continuar.')
            });
    }

    // Es necesario si en algun momento se desmontara el clock
    componentWillUnmount(){
        clearInterval(this.intervalId);
        clearInterval(this.clock_duration)
        clearInterval(this.clock_interval)
        clearInterval(this.clock_again)
        console.log("Ya borre el clock")
    }

    timer_start() {
        console.log("hora de capturar");
        this.startRecording();
        clearInterval(this.intervalId)
      }

    timer_duration(){
        console.log("Guardar y enviar video")
        this.stopRecording()
        clearInterval(this.clock_duration)
    }

    timer_interval(){
        console.log("Grabar nuevamente")
        this.restartAudio();
        this.startRecording();
        clearInterval(this.clock_interval)
    }

    timer_again(){
      this.setState({state:"Preparado",uploadPercentage:0});
      clearInterval(this.clock_again)
  }

    exit() {      
      this.props.history.push('/admision/test')
      var tracks = this.localStream.getTracks();
      tracks.forEach(function(track){
        track.stop();
      });
    }

    startRecording() {
        //e.preventDefault();
        // wipe old data chunks
        this.chunks = [];
        // start recorder with 10ms buffer
        this.mediaRecorder.start(10);
        console.log("Video type",this.mediaRecorder.mimeType)
        // say that we're recording
        this.setState({state:"Grabando"});
        this.clock_duration = setInterval(this.timer_duration.bind(this), this.state.time_recording);
    }

    stopRecording() {
        //e.preventDefault();
        // stop the recorder
        this.mediaRecorder.stop();
        // save the video to memory
        this.saveAudio();
    }

    saveAudio() {
        // convert saved chunks to blob
        const blob = new Blob(this.chunks, {type : "video/webm"});
        console.log("Blob",blob)
        // generate video url from blob
        const audioURL = URL.createObjectURL(blob);       
        //console.log(audioURL)
        //console.log(this.mediaRecorder.mediaBlobUrl)
        // append videoURL to list of saved videos for rendering
        this.setState({url_video:audioURL,blob_video:blob, recorded:true, state:"Guardando"});
        this.sendRecording();
    }

    restartAudio() {
      // filter out current videoURL from the list of saved videos
      this.setState({recorded: false, url_video: null, videoSrc:null,
         blob_video:null, uploadPercentage:0});
    }

    sendRecording() {
      this.setState({state:"Enviando"})
      let formdata = new FormData()
      formdata.append("file",this.state.blob_video,'test.webm')
      formdata.append("id_number",this.prev_state["id_number"])
      formdata.append("id_admission",this.prev_state["id_admission"])
      formdata.append("code_group",this.prev_state["candidateCode"])
      formdata.append("av_type","AV")

      const config = {
        headers: {
          'Content-Type': 'multipart/form-dat',
        },
        onUploadProgress: (progressEvent) => {
          const {loaded, total} = progressEvent
          let percent = Math.floor( (loaded * 100) / total)
          console.log(`${loaded}kb of ${total}kb | ${percent}%`)
          if( percent < 100 ){
            this.setState({ uploadPercentage: percent })
          }
        }        
      };


      axios.post(
        "https://biometria-api.udea.edu.co/admissionExam/tests/testCapture",
        formdata,
        config).then(res => { 
          console.log(res)
          this.setState({uploadPercentage: 100, state: "Enviado"})
          this.clock_interval = setInterval(this.timer_interval.bind(this), this.state.time_interval);
          this.clock_again = setInterval(this.timer_again.bind(this), this.state.time_again);
          this.setState((state, props) => ({
            count: state.count + 1
          }));
          console.log(this.state.count)
        },(err)=>{
          console.log("Tenemos un error", err)
        })
    }

    render() {
        const {url_video, recorded, uploadPercentage, state, count} = this.state;

        return (
            <Container>
              <div className="cinta-superior">
                <img
                  src={require("./../static/logoudea.svg")}
                  width="320"
                  height="100"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </div>
               <div className="text-center pt-3">
                  <h2><b>Captura de habla y rostro</b></h2>
              </div>
              <hr />
              <div className="text-center pt-3">
                <h3>Estado: {state}</h3>
                {uploadPercentage > 0 && <ProgressBar now={uploadPercentage} active="true" label={`${uploadPercentage}%`} /> }
                {uploadPercentage > 0 && <br />}
              </div>
              <br/>
                <Row>
                    <Col center-block="true" >
                      <div className="embed-responsive embed-responsive-16by9">
                        <video
                          muted
                          id={this.props.id}
                          ref={this.videoTag}
                          audio="false"
                          width="200"
                          height="200"
                          autoPlay
                          title={this.props.title}>
                          controls muted
                        </video> 
                      </div>  
                    </Col>
                    <Col center-block="true">
                      <div className="embed-responsive embed-responsive-16by9">
                          {recorded && <video 
                              controls="controls"
                              width={200}
                              height={200}
                              src={url_video}
                          />}
                      </div>  
                    </Col>
                </Row>
                <br />
                  <center>
                      {count > -1 && <Button
                          variant="danger mr-1"
                          onClick={this.exit}>
                          Salir
                      </Button>}
                  </center>
            </Container> 
        )}
}


export default withRouter(RecordTestAdm)