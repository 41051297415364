import React from 'react';
import {BrowserRouter,Switch, Route} from "react-router-dom" 
import axios from 'axios';

//import GuardedUser from './guardians/GuardedUser'
//import GuardedTest from './guardians/GuardedTest'
//import GuardedPos from './guardians/GuardedPos'
//import GuardedAdmin from './guardians/GuardedAdmin'

import GuardedUserAdmission from './guardians/GuardedUserAdmission'
import GuardedAdminAdmission from './guardians/GuardedAdminAdmission'



/*import RecordApi from './register/RecordApi';
import Login from './register/login';
import Register from './register/register'
import Finish from './register/finish'

import LoginAdmin from './admin/login'
import Results from './admin/results'
import Addcode from './admin/addcode'
import Deletecode from './admin/deletecode'
import Model from './admin/model'
import StudentRegister from './admin/register'
import Addtask from './admin/addtask'
import Deletetask from './admin/deletetask'
import Addstudents from './admin/addstudents'

import LoginTest from "./test/login"
import RecordTest from "./test/record"
import FormTest from "./test/form"
*/

import WebcamStream from './testcapture'

import LoginAdmRegister from './admission/login_reg'
import CaptureAdmRegister from './admission/capture_reg'
import FinishAdmRegister from './admission/finish_reg'
//import LoginAdm from './admission/login_adm'
//import RegisterAdm from './admission/register_adm'

import LoginAdminAdmission from './admin_admission/login_admin'
import ResultsAdm from './admin_admission/results_adm'
import ResultsExamAdm from './admin_admission/results_exam'
import ResultsSimAdm from './admin_admission/results_sim'
import Addadmission from './admin_admission/create_admission'
import ChangeDates from './admin_admission/change_dates'
import CreateUser from './admin_admission/create_user'

import LoginAdmTest from './test/login_adm'
import RecordTestAdm from './test/record_adm'

// Examen posgrados ingenieria
/*import CapturePosRegister from './postgraduates/capture_biom'
import LoginPos from './postgraduates/login_biom'
import LoginPosID from './postgraduates/login_id'
import RegisterPos from './postgraduates/register_metadata'
import FinishPosRegister from './postgraduates/finish_biom'
import GuardedPosID from './guardians/GuardedPosID'
*/
//Examen posgrados ingenieria


class App extends React.Component {
    render() {
      return (
        <BrowserRouter>
            <ModalSwitch />
        </BrowserRouter>
      );
    }
}
  
function ModalSwitch() {
    return (
      <div>
        <Switch>

          {/*
          <Route exact path="/" children={<Login />} />
          <GuardedUser path='/capture' component={RecordApi}/>
          <Route exact path="/register" children={<Register />} />
          <Route exact path="/finish" children={<Finish />} />

          <Route exact path="/admin/" children={<LoginAdmin />} />
          <GuardedAdmin path="/admin/results" component={Results} />
          <GuardedAdmin path="/admin/register" component={StudentRegister} />
          <Route exact path="/admin/model/" children={<Model />} />
          <GuardedAdmin path="/admin/addcode/" component={Addcode} />
          <GuardedAdmin path="/admin/delcode/" component={Deletecode} />
          <GuardedAdmin path="/admin/addtask/" component={Addtask} />
          <GuardedAdmin path="/admin/deltask/" component={Deletetask} />
          <GuardedAdmin path="/admin/addstud/" component={Addstudents} />

          <Route exact path="/test/login" children={<LoginTest />} />
          <GuardedTest path="/test/capture" component={RecordTest} />
          <Route exact path="/test/form" children={<FormTest/>} />

          */}

          <Route exact path="/testcapture" children={<WebcamStream />} />

          <GuardedUserAdmission path="/admision/captura" component={CaptureAdmRegister} />
          <Route exact path="/admision" children={<LoginAdmRegister />} />
          <Route exact path="/admision/final" children={<FinishAdmRegister />} />
          <Route exact path="/admision/test" children={<LoginAdmTest />} />
          <Route exact path="/admision/test/capture" children={<RecordTestAdm />} />

          <Route exact path="/admision/admin/" children={<LoginAdminAdmission />} />
          <GuardedAdminAdmission path="/admision/admin/results" component={ResultsAdm} />
          <GuardedAdminAdmission path="/admision/admin/resultsexam" component={ResultsExamAdm} />
          <GuardedAdminAdmission path="/admision/admin/resultsim" component={ResultsSimAdm} />
          <GuardedAdminAdmission path="/admision/admin/create" component={Addadmission} />
          <GuardedAdminAdmission path="/admision/admin/changedate" component={ChangeDates} />
          <GuardedAdminAdmission path="/admision/admin/createuser" component={CreateUser} />

          {/*<Route exact path="/admision/login" children={<LoginAdm />} />
          <Route exact path="/admision/register/" children={<RegisterAdm />} />*/}

          {/* Examen posgrados ingenieria
          <Route exact path="/posgrado" children={<LoginPos />} />
          <GuardedPos path="/posgrado/registrobiom" component={CapturePosRegister} />
          <Route exact path="/posgrado/login" children={<LoginPosID />} />
          <GuardedPosID path="/posgrado/register" component={RegisterPos} />
          <Route exact path="/posgrado/finish" children={<FinishPosRegister />} />
          Examen posgrados ingenieria */}

        </Switch>
      </div>
    );
  }

// Token management
//axios.defaults.headers.post['Content-Type'] = 'multipart/form-dat';


axios.interceptors.request.use(request =>{
    console.log("From interceptor: ",request)
    return request;
  }
  ,error => {
    console.log("Error from interceptor",error)
    // Token invalido
    if (error.response.status === 401) {

      // Eliminar el token y redireccionar nuevamente a register('/')
      delete axios.defaults.headers.common['Authorization'];
      this.props.history.push('/')
      console.log("Token eliminado y redireccionado")
      return Promise.reject(error);
    }
  }
);


export default App;
  