import React from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import './../style.css';
import {Form, Button, Container, Modal} from 'react-bootstrap'

var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class LoginAdmTest extends React.Component {

  constructor(props){
      super(props)
      this.state = {
        id_admission:{},
        id_admission_user:"",
        careers:{},
        career:"",
        id_number:"",
        show_popup:false,
        popup:"",
        validate: true,
        validated: false,

      }
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeID = this.handleChangeID.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
      const config = {headers: {'Token-Security':static_token}}
        axios.get('https://biometria-api.udea.edu.co/admissionExam/Results/listAdmission',
        config)
        .then(res => {
          console.log(res.data)
          let list = res.data
          let dict = {}; // Convert list to dict
          for (let i=0; i< list.length; i++ ) {
                  dict[list[i]] = list[i];
          }
          console.log(dict);
          this.setState({id_admission:dict})
        })
    }

    handleChangeID=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
        var body = new FormData();
        body.append("id_admission",value)
        const config = {
          headers: { 'Content-Type': 'multipart/form-dat',
                    'Token-Security':static_token}
        }
        axios.post('https://biometria-api.udea.edu.co/admissionExam/Results/listCarriersCode',
        body,
        config)
        .then(res => {
          console.log(res.data)
          this.setState({careers:res.data})
        },
        (err)=>{
            console.log("Tenemos un error", err)
        })

    }

    handleChange=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value})
    }



   handleClose = () => {
    this.setState({show_popup:false});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        console.log("invalid form");
    }
    this.setState({validated: true})

    if(form.checkValidity()){
      console.log("id_number:",this.state.id_number)
      console.log("id_admision:",this.state.id_admission_user)
      console.log("career_code:",this.state.career)
      
      var body = new FormData();
      body.append("id_number",this.state.id_number)
      body.append("id_admision",this.state.id_admission_user)
      body.append("career_code",this.state.career)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-dat',
        }
      };

      axios.post(
        "https://biometria-api.udea.edu.co/admissionExam/tests/modelExistAndGroup",
        body,
        config).then(res => { 
          console.log(res.data)
          if((res.data["modelTrainExist"]===1) && (res.data["admissionExist"]===1) && (res.data["candidateExist"]===1)){
            this.props.history.push({
              pathname:'/admision/test/capture',
              state:{"id_admission":this.state.id_admission_user, "id_number":this.state.id_number,"candidateCode":res.data["candidateCode"],
                "startRecording":res.data["startRecording"], "intervalRecording":res.data["intervalRecording"]},
            })
          }
          else if (res.data["candidateExist"]===0) {
            this.setState({popup:"Aspirante no registrado", show_popup:true})
          }
          else if ((res.data["modelTrainExist"]===0) && (res.data["candidateExist"]===1)){
            this.setState({popup:"Aspirante sin modelo biométrico", show_popup:true})
          }
          else if ((res.data["validCodeCareer"]===0)){
            this.setState({popup:"Código de programa invalido", show_popup:true})
          }
          else {
            this.setState({popup:"Por favor contacte el adminstrador", show_popup:true})
          }
        },(err)=>{
          console.log("Tenemos un error", err)
        })
    }
  }

  

  render() {
    return (
      <Container>
        <div className="cinta-superior">
          <img
            src={require("./../static/logoudea.svg")}
            width="320"
            height="100"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </div>
        <div className="text-center pt-3">
          <h2><b>Examen de admisión UdeA (pruebas)</b></h2>
        </div>
        <hr />
        <div className="login-page">
          <div className="form">
            <Form noValidate validated={this.state.validated} onSubmit={e => this.handleSubmit(e)}>
            <Form.Group>
                <Form.Control 
                    required
                    as="select"  
                    name="id_admission_user" 
                    value={this.state.id_admission_user}
                    onChange={this.handleChangeID}>
                    <option value="" disabled>Id admisión...</option>
                    {Object.entries(this.state.id_admission).map( ([key, value]) => {
                        return <option key={key} value={value}>{key}</option>;
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control 
                    required
                    as="select"  
                    name="career" 
                    value={this.state.career}
                    onChange={this.handleChange}>
                    <option value="" disabled>Programa...</option>
                    {Object.entries(this.state.careers).map( ([key, value]) => {
                        return <option key={key} value={value}>{key}</option>;
                    })}
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Control 
                  required
                  name="id_number"
                  type="text"
                  pattern="[0-9]*"
                  value={this.state.id_number} 
                  placeholder="Doc. identidad"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Solo se permiten números (no agregue puntos)</Form.Control.Feedback>
              </Form.Group>
              <Button
                variant="success mr-1"
                type="submit">
                Ingresar
              </Button>
            </Form>
          </div>
        </div>


        {/* Mensaje final */}
        <Modal show={this.state.show_popup} onHide={this.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

      </Container>
    )
  }
}


export default withRouter(LoginAdmTest)