import React from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {Table, Container, Form, Row, Col, Button, Modal, Tooltip, OverlayTrigger} from 'react-bootstrap'
import Navadmin from './nav';
import { CSVLink } from "react-csv";
import Plot from 'react-plotly.js';
const time_refreshToken = 600000

var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class ResultsExamAdm extends React.Component {

    constructor(props){
        super(props)
        this.state = {
          results:[],
          url_video:null,
          state:true,
          state_search:false,
          id_search:"",
          id_select:"",
          pos_scroll: 0,
          name:"",
          doc_identidad:"",
          resultspersonal:[],
          groups:{},
          careers:{},
          show_video:false,
          text_video:"",
          show_result:false,
          show_result_ID:false,
          show_group:false,
          show_screenshot:false,
          career:"",
          group:"",
          index_id:-1,
          validated:false,
          validated_ID:false,
          video_select:-1,
          show_popup:false,
          show_popup_speech:false,
          show_popup_face:false,
          show_popup_rostros:false,
          show_popup_answers:false,
          show_popup_ayuda_evaludea:false,
          show_popup_evaludea:false,
          show_table_software:false,
          show_register:false,
          num_device:0,
          num_screen:0,
          num_software:0,
          list_software:[],
          leave_token:false,
          url_foto: null,
          url_id: null,
          url_video_register:null,
          url_screenshot:null,
          csv_report:[],
          time_answer:[],
          x_answers:[],
          y_answers:[],
          answers:[],
          headers:[],
          show_answers:false,
          name_file_answers:""
        }

    this.renderResults_general = this.renderResults_general.bind(this);
    this.renderResults_specific = this.renderResults_specific.bind(this);
    this.showResults = this.showResults.bind(this);
    this.search_ID = this.search_ID.bind(this);
    this.return = this.return.bind(this);
    this.plotAnswers = this.plotAnswers.bind(this);
    this.update_general = this.update_general.bind(this);
    this.leave = this.leave.bind(this);
    }

    componentDidMount() {
        clearInterval(this.clock_duration) // clean clock to refresh token

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))
        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
            'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                console.log(res.data["tokenSecurity"])
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);

                var body = new FormData();
                body.append("id_admission",localStorage.getItem('id_admission'))
                const config = { headers: { 'Content-Type': 'multipart/form-dat',
                                'Authorization':res.data["tokenSecurity"]}}
                axios.post('https://biometria-api.udea.edu.co/admissionExam/Results/listCarriers',
                body,
                config)
                .then(res => {
                    // Create items array
                    var items = Object.keys(res.data).map(function(key) {
                        return [key, res.data[key]];
                    });
                    
                    // Sort the array based on the second element
                    items.sort(function(first, second) {
                        return first[1] - second[1];
                    });
                    
                    // Create a new array with only the first 5 items
                    let dict = {}; // Convert list to dict
                    for (let i=0; i< items.length; i++ ) {
                        if (items[i][0] !== 'Preregistro'){
                            dict[items[i][0]] = items[i][1];
                        }
                    }
                    this.setState({careers:dict})
                },
                (err)=>{
                    console.log("Tenemos un error", err)
                })
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }
    
    timer_token(){ // Timer for refresh token
        console.log("refresh token")
        clearInterval(this.clock_duration)

        var instance_refresh = axios.create(); //create new instance for send info without token
        delete instance_refresh.defaults.headers.common['Authorization'];
        var body_refresh = new FormData();
        body_refresh.append("email",localStorage.getItem('email_user_admission'))
        body_refresh.append("password",localStorage.getItem('password_admission'))
        body_refresh.append("admission",localStorage.getItem('id_admission'))

        const config_ref = {
            headers: {'Content-Type': 'multipart/form-dat',
            'Token-Security':static_token}
        };
        instance_refresh.post("https://biometria-api.udea.edu.co/admissionExam/general/login",
            body_refresh, config_ref).then(res => { 
                this.RefreshToken(res.data["tokenSecurity"])
                this.clock_duration = setInterval(this.timer_token.bind(this), time_refreshToken);
          },(err)=>{
            console.log("Tenemos un error", err)
            this.setState({leave_token:true})
          })
    }

    RefreshToken = token => {
        axios.defaults.headers.common['Authorization'] = token;
        console.log("--------Token Refresh-----")
    }

    leave(){
        localStorage.clear();
        delete axios.defaults.headers.common['Authorization'];
        console.log("--------Token ELIMINADO-----")
        this.props.history.push('/admision/admin')
      }

    handleCloseVideo = () => {
        this.setState({show_video:false, text_video:"", show_register:false, url_foto: null, url_id: null, url_video_register:null});
      }

   showResults = (event) => { //Post resultados generales
        console.log(this.state.group)
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("invalid form");
        }
        this.setState({validated: true})
        if(form.checkValidity()){
            var body = new FormData();
            body.append("id_group",this.state.group)
            body.append("order","default")
            body.append("type_exam","test")
            const config = {headers: {'Content-Type': 'multipart/form-dat',}};
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/resultsDocente",
              body,
              config).then(res => { 
                console.log(res.data)
                this.setState({show_result:true,results:res.data,state:true,url_video:null,video_select:-1,show_video:false,index_id:-1, show_answers:false, name_file_answers:"", csv_report:[]})
              }
              ,(err)=>{
                console.log("Tenemos un error", err)
              })
        }
   }

   return(){
    this.setState({state: true, url_video:null,video_select:-1,show_video:false,index_id:-1, csv_report:[], show_answers:false, name_file_answers:""})
    let time_aux = this.state.pos_scroll
    setTimeout(function(){
        window.scroll({top:time_aux, behavior:'smooth'})
    }, 200);
    if (this.state.id_select.length>0){
        console.log("Posicion scroll: ", this.state.pos_scroll)
    }
   }

   update_general() { // Boton actualizar
       if(this.state.state){ //Actualizar resultado general
            var body = new FormData();
            body.append("id_group",this.state.group)
            body.append("order","default")
            body.append("type_exam","test")
            const config = {headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Results/resultsDocente",
            body,
            config).then(res => { 
                console.log(res.data)
                this.setState({results:res.data})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
            })
        }
        else{ // Actualizar resultado especifico
            var body_upd = new FormData();
            body_upd.append("id_group",this.state.group)
            body_upd.append("id_number",this.state.results[this.state.index_id]["id"])
            body_upd.append("order","default")
            body_upd.append("type_exam","test")
            const config = { headers: { 'Content-Type': 'multipart/form-dat', }}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Results/resultsVideosByUser",
            body_upd,
            config).then(res => { 
                console.log(res.data["results"])
                this.setState({resultspersonal:res.data["results"],video_select:-1,show_video:false})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
            })
        }
    }

    handleChangeCareer=(event)=> {
        const {name, value} = event.target
        this.setState({[name]: value, show_group:true, show_result:false, validated:false, group:"", csv_report:[], show_answers:false, name_file_answers:""})

        var body = new FormData();
        body.append("code_udea",value) 
        const config = { headers: { 'Content-Type': 'multipart/form-dat'}}
        axios.post('https://biometria-api.udea.edu.co/admissionExam/Results/listGroups',
        body,
        config)
        .then(res => {
            var items = Object.keys(res.data).map(function(key) {
                return [key, res.data[key]];
            });
            
            // Sort the array based on the second element
            items.sort(function(first, second) {
                return first[1] - second[1];
            });
            
            // Create a new array with only the first 5 items
            let dict = {}; // Convert list to dict
            for (let i=0; i< items.length; i++ ) {
                dict[items[i][0]] = items[i][1];
            }
            this.setState({groups:dict})
        },
        (err)=>{
            console.log("Tenemos un error", err)
        })
    }

    handleChange=(event)=> {
    const {name, value} = event.target
    this.setState({[name]: value})
    }

    select_id=(index)=> { // Post resultado especifico

        this.setState({pos_scroll:window.scrollY})

        var body = new FormData();
        //body.append("id_group",this.state.group)
        if(this.state.state_search){
            this.setState({group: this.state.results[index]["idGroup"]})
        }
        body.append("id_group",!this.state.state_search?this.state.group:this.state.results[index]["idGroup"])
        body.append("id_number",this.state.results[index]["id"])
        body.append("order","default")
        body.append("type_exam","test")
        const config = {headers: {'Content-Type': 'multipart/form-dat',}}
        axios.post(
        "https://biometria-api.udea.edu.co/admissionExam/Results/resultsVideosByUser",
        body,
        config).then(res => { 
            console.log(res.data["results"])
            window.scroll({top:0, behavior:'smooth'})
            this.setState({name:res.data["name"],resultspersonal:res.data["results"],
            state:false,index_id:index, state_search:false, show_result_ID:false, id_select:this.state.results[index]["id"]})
        }
        ,(err)=>{
            console.log("Tenemos un error", err)
        })


        // GET answers from Ude@
        let url_answers = 'https://appudearroba.udea.edu.co/admision_api/answers/'+localStorage.getItem('id_admission')+'/'+this.state.results[index]["id"]
        console.log(url_answers)
        axios.get(url_answers) // <----- CAMBIAR POR URL SEMESTRE
        .then(res => {
          let list = res.data
          let dict_answers = []
          let time_answer = []
          let answers = []
          if(list.length>0){
            for (let i=0; i< list.length; i++ ) {
                var temp_date = {"date": list[i]['createatanswer']}
                var temp_anserws = list[i]['consecutivequiestions'];
                var temp_dict = Object.assign({}, temp_date, temp_anserws);
                dict_answers.push(temp_dict)
                time_answer.push(new Date(list[i]['createatanswer']).toLocaleTimeString('en-US', { hour12: false }))
                answers.push(temp_anserws)
            }            
            let temp_headers = []
            temp_headers.push({label: "fecha", key: "date"})
            for (let i=1; i<= 80; i++ ) {
                temp_headers.push({label: "Resp."+i.toString(), key: i.toString()})
            }
            this.setState({csv_report:dict_answers, time_answer: time_answer, answers: answers,headers:temp_headers, show_answers:true, name_file_answers:this.state.results[index]["id"]+'.csv'});
            }
            
        }
        ,(err)=>{
            console.log("Tenemos un error", err)
        })
    }

    plotAnswers(){

        const answers_temp = this.state.answers
        const time_answers_temp = this.state.time_answer

        let answer_norm = []
        answers_temp.forEach(row => {
            var row_answer = Object.values(row)
            const result = row_answer.map((item) => item === 'X' ? 0 : 1);
            answer_norm.push(result)
          });
          // Compute the difference between consecutive rows and sum the absolute values
        const matriz_answers = answer_norm.map(row => row.map(Number));
        const delta_respuestas = matriz_answers.slice(1).reduce((acc, row, index) => {
            const prevRow = matriz_answers[index];
            acc.push(row.reduce((sum, value, index) => sum + Math.abs(value - prevRow[index]), 0));
            return acc;
        }, []);

        console.log(delta_respuestas)
        console.log(time_answers_temp)
        this.setState({x_answers: time_answers_temp, y_answers:delta_respuestas, show_popup_answers:true})
    }

    select_video=(index)=> {
        this.setState({show_video:true, url_video: null, text_video: "Cargando ..."})
        console.log("id Video:",index)
        console.log("Video seleccionada:",this.state.resultspersonal[index]["idVideo"])
        var base = "https://biometria-api.udea.edu.co/admissionExam/Results/loadVideoTest/"
        var dir_url = base.concat((this.state.resultspersonal[index]["idVideo"]).toString())
        console.log(dir_url)

        var config = { responseType: 'blob' }
        axios.get(dir_url,
        config)
        .then(res => {
          console.log(res)
          const obj = URL.createObjectURL(res.data)
          this.setState({ url_video: obj, video_select:index,  text_video: ""})
        })

    }


    select_eval_udea=(index)=> {

        if(this.state.results[index]["evalUdea"]>0){
            var body = new FormData();
            if(this.state.state_search){
                this.setState({group: this.state.results[index]["idGroup"]})
            }
            body.append("id_group",!this.state.state_search?this.state.group:this.state.results[index]["idGroup"])
            body.append("id_number",this.state.results[index]["id"])
            body.append("type_exam","test")
            const config = {headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Results/logsEvalUdeaByUser",
            body,
            config).then(res => { 
                let list = res.data["listSoftware"]
                let list_final = []
                for (let i=0; i< list.length; i++ ) {
                        let dict = {}
                        dict["fecha"] = res.data["datesSoftware"][i].substring(0,8);
                        dict["software"] = res.data["listSoftware"][i];
                        dict["id_screenshot"] = res.data["listScreenShots"][i]
                        list_final.push(dict)
                }
                console.log(list_final)
                this.setState({num_device:res.data["numDevice"], num_screen: res.data["numScreen"],
                                num_software: res.data["numSoftware"], show_popup_evaludea:true,
                                list_software: list_final, doc_identidad:this.state.results[index]["id"],
                                show_table_software:res.data["numSoftware"]>0?true:false})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
            })
        }
    }

    select_screenshot_udea=(index)=>{
        this.setState({show_screenshot:true})
        console.log(this.state.list_software[index]['id_screenshot'])
        var base_photos = "https://biometria-api.udea.edu.co/admissionExam/Results/security/loadScreenShot/"

        // Peticion foto documento
        var url_idPhotoDocument = base_photos.concat(this.state.list_software[index]['id_screenshot'].toString())
        var config = { responseType: 'blob' }
        axios.get(url_idPhotoDocument,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_screenshot: obj })
            })
    }

    popup_speech = () =>{
        this.setState({show_popup_speech:true});
      }

    popup_face = () =>{
        this.setState({show_popup_face:true});
    }

    popup_rostros = () =>{
        this.setState({show_popup_rostros:true});
    }

    popup_evaludea = () =>{
        this.setState({show_popup_ayuda_evaludea:true});
    }

    handleClose = () => {
        this.setState({show_popup_speech:false, show_popup_face:false, show_popup_rostros:false, show_popup_ayuda_evaludea:false, show_table_software:false, show_popup_answers:false});
    }

    handleRegister = () => {

        if(!this.state.show_register){


            var body = new FormData();
            body.append("id_number",this.state.results[this.state.index_id]["id"])
            body.append("id_admission",localStorage.getItem('id_admission'))
            const config_search = { headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/resultsComparisonRegisterByID",
              body,
              config_search).then(res => { 
                console.log(res.data)

                var base_photos = "https://biometria-api.udea.edu.co/admissionExam/Results/security/loadPhoto/"

                // Peticion foto documento
                var url_idPhotoDocument = base_photos.concat(res.data[0]["idPhotoDocument"].toString())
                var config = { responseType: 'blob' }
                axios.get(url_idPhotoDocument,
                    config)
                    .then(res => {
                      console.log(res)
                      const obj = URL.createObjectURL(res.data)
                      this.setState({ url_id: obj})
                    })
        
                // Peticion foto actual
                var url_idPhotoPersonal = base_photos.concat(res.data[0]["idPhotoPersonal"].toString())
                axios.get(url_idPhotoPersonal,
                    config)
                    .then(res => {
                      console.log(res)
                      const obj = URL.createObjectURL(res.data)
                      this.setState({ url_foto: obj})
                    })
    
            // Peticion video biometrico
            var base_videos = "https://biometria-api.udea.edu.co/admissionExam/Results/loadVideo/"
            var url_video_mon = base_videos.concat(res.data[0]["idModel"]===null ? "":res.data[0]["idModel"].toString())
            axios.get(url_video_mon,
            config)
            .then(res => {
              console.log(res)
              const obj = URL.createObjectURL(res.data)
              this.setState({ url_video_register: obj})
            })
            }
            ,(err)=>{
            console.log("Tenemos un error", err)
            })
        }
        this.setState({show_register:!this.state.show_register});
    }

    export_results = () => {
        var base = "https://biometria-api.udea.edu.co/admissionExam/Results/exportResults/"
        var dir_url = base.concat(this.state.group)

        axios.get(dir_url,
            { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                console.log(res.data)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'resultados_grupo.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    export_general_results = () => {
        var base = "https://biometria-api.udea.edu.co/admissionExam/Results/exportResultsAll/"
        var dir_url = base.concat(localStorage.getItem('id_admission'),'_test')

        axios.get(dir_url,
            { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                console.log(res.data)
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'resultados_generales.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    export_results_evaludea = () => {
        var base = "https://biometria-api.udea.edu.co/admissionExam/Results/exportLogsEvalByUser/"
        var dir_url = base.concat(this.state.group,'_',this.state.doc_identidad,'_test')
        console.log(dir_url)

        axios.get(dir_url,
            { responseType: 'blob' })
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                console.log(res.data)
                const link = document.createElement('a');
                link.href = url;
                var name_file ="Logs_EvalUdea_"
                link.setAttribute('download', name_file.concat(this.state.doc_identidad,".csv")); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }


    order_results = (label) =>{ // Ordenar los resultados por: speech, face, num_faces, date
        if(this.state.state){ // Resultados generales
            console.log("Resultados generales -- ordenar por: ",label)

            var body = new FormData();
            body.append("id_group",this.state.group)
            body.append("order",label)
            body.append("type_exam","test")
            const config = { headers: { 'Content-Type': 'multipart/form-dat', } }
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Results/resultsDocente",
            body,
            config).then(res => { 
                console.log(res.data)
                this.setState({results:res.data})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
            })
        }
        else{ // Resultados especificos
            console.log("Resultados especificos -- ordenar por: ", label)

            var body_upd = new FormData();
            body_upd.append("id_group",this.state.group)
            body_upd.append("id_number",this.state.results[this.state.index_id]["id"])
            body_upd.append("order",label)
            body_upd.append("type_exam","test")
            const config = { headers: { 'Content-Type': 'multipart/form-dat',}}
            axios.post(
            "https://biometria-api.udea.edu.co/admissionExam/Results/resultsVideosByUser",
            body_upd,
            config).then(res => { 
                console.log(res.data["results"])
                this.setState({resultspersonal:res.data["results"],video_select:-1,show_video:false})
            }
            ,(err)=>{
                console.log("Tenemos un error", err)
            })
        }
    }

   renderResults_general =(result,index) => {
    return(
        <tr key={index}>
            <td>
                <Button style={{color:result["id"]===this.state.id_select && 'red'}} variant="link" onClick={() => this.select_id(index)}>{
                    result["id"]
                }</Button>
            </td> 
            <td>
                {result["speechScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorSpeech"]}}/>
                </div>
            </td>
            <td>
                {result["faceScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorFace"]}}/>
                </div>
                
            </td>
            <td>
                {result["numFaces"]}
                <div className="parent">
                    <div className="circle" style={{background: result["numFaces"] === 1 ? 'green':'red'}}/>
                </div>
            </td>
            <td>
                <button className="button_image" onClick={() => this.select_eval_udea(index)}>
                 <img
                    src={require(result["evalUdea"] !== 0 ? "./../static/warning.png":"./../static/success_green.png")}
                    width={result["evalUdea"] !== 0 ? 35:30}
                    height={result["evalUdea"] !== 0 ? 35:30}
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </button>

            </td>
        </tr>
    )
   }

   renderResults_specific =(result,index) => {
    return(
        <tr key={index}>
            <td>{result["date"]}</td>
            <td>                
                {result["speechScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorSpeech"]}}/>
                </div>
            </td>  
            <td>                
                {result["faceScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorFace"]}}/>
                </div>
            </td>  
            <td>
                {result["numFaces"]}
                <div className="parent">
                    <div className="circle" style={{background: result["numFaces"] === 1 ? 'green':'red'}}/>
                </div>
            </td>  
            <td>
                <button className="button_image" onClick={() => this.select_video(index)}>
                    <img
                    src={require(index!==this.state.video_select ? "./../static/play_green.png":"./../static/play_red.png")}
                    width="35"
                    height="35"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </button>

            </td>         
        </tr>
    )
   }

   renderTableID =(result,index) => {
    return(
        <tr key={index}>
            <td>                
                <Button variant="link" onClick={() => this.select_id(index)}>{result["id"]}</Button>
            </td>      
            <td>                
                {result["carrier"]}
            </td>   
            <td>
                {result["speechScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorSpeech"]}}/>
                </div>
            </td>
            <td>
                {result["faceScore"].toFixed(2)}
                <div className="parent">
                    <div className="circle" style={{background: result["colorFace"]}}/>
                </div>
                
            </td>
            <td>
                {result["numFaces"]}
                <div className="parent">
                    <div className="circle" style={{background: result["numFaces"] === 1 ? 'green':'red'}}/>
                </div>
            </td>
            <td>
                <button className="button_image" onClick={() => this.select_eval_udea(index)}>
                    <img
                        src={require(result["evalUdea"] !== 0 ? "./../static/warning.png":"./../static/success_green.png")}
                        width={result["evalUdea"] !== 0 ? 35:30}
                        height={result["evalUdea"] !== 0 ? 35:30}
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo"
                        />
                </button>

            </td>
        </tr>
    )
    }

    renderTableEval =(result,index) => {
        return(
            <tr key={index}>
                <td>                
                    {result["fecha"]}
                </td>      
                <td>                
                    {result["software"]}
                </td>
                <td>
                {result["id_screenshot"]!==""&&
                <button className="button_image" onClick={() => this.select_screenshot_udea(index)}>
                <img
                    src={require("./../static/camara.png")}
                    width={25}
                    height={25}
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                    />
                </button>
                }    
                </td>   
            </tr>
        )
        }


   search_ID() { // Activar buscar por ID
    this.setState({state_search:!this.state.state_search, career:"", groups:{} ,group:"", id_search:"", validated:false, validated_ID:false, results:[], show_result:false, show_result_ID:false, csv_report:[], show_answers:false, name_file_answers:""})
    }

    showResultsperID = (event) => { 
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log("invalid form");
        }
        this.setState({validated_ID: true})

        if(form.checkValidity()){
            console.log(this.state.id_search)
            
            var body = new FormData();
            body.append("id_number",this.state.id_search)
            body.append("id_admission",localStorage.getItem('id_admission'))
            body.append("type_exam","test")
            const config = { headers: {'Content-Type': 'multipart/form-dat',}}
            axios.post(
              "https://biometria-api.udea.edu.co/admissionExam/Results/resultsTestRegisterByID",
              body,
              config).then(res => { 
                console.log(res.data)
                if(res.data.length>0){
                    this.setState({show_result_ID:true,results:res.data})
                }
                else{
                    this.setState({popup:"Doc. identidad no encontrado", show_popup:true, show_result_ID:false,results:[]})
                }
              }
              ,(err)=>{
                console.log("Tenemos un error", err)
                this.setState({popup:"No pudo ser procesado el doc. identidad, contacte al administrador", show_popup:true, show_result_ID:false,results:[]})

              })
              
        }
   }

   handleClosePopup = () => {
    this.setState({show_popup:false, show_popup_evaludea:false});
    }

    handleClosePopupScreenshot = () => {
        this.setState({show_screenshot:false, url_screenshot:null});
    }

   renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Buscar usuario por ID
    </Tooltip>
    );

    renderTooltip_answers = (props) => (
    <Tooltip id="button-tooltip" {...props}>
        Ver dinámica de respuestas
    </Tooltip>
    );

render() {
    return (
      <Container>
        <Navadmin />
        <div className="text-center pt-3">
        <h2><b>{this.state.state||this.state.state_search?"Resultados examen de admisión UdeA":this.state.name}</b></h2>
        </div>
        <hr />
        {!this.state.state_search && <Row>
            <Col md={4}>
                <Form.Group as={Row}>
                    <Form.Label column md={3}>
                        Carrera:
                    </Form.Label>
                    <Col md={8}>
                        <Form.Control 
                            required
                            as="select"  
                            name="career" 
                            value={this.state.career}
                            onChange={this.handleChangeCareer}>
                            <option value="" disabled>Elija...</option>
                            {Object.entries(this.state.careers).map( ([key, value]) => {
                            return <option key={key} value={value}>{key}</option>;
                        })}
                        </Form.Control>
                    </Col>
                </Form.Group>
            </Col>

            <Col md={6}>
                {this.state.show_group && <Form noValidate validated={this.state.validated} onSubmit={e => this.showResults(e)}>
                    <Form.Group as={Row}>
                        <Form.Label column md={2}>
                            Grupo:
                        </Form.Label>
                        <Col md={4}>
                            <Form.Control 
                                required
                                as="select"  
                                name="group" 
                                value={this.state.group}
                                onChange={this.handleChange}>
                                <option value="" disabled>Elija...</option>
                                {Object.entries(this.state.groups).map( ([key, value]) => {
                                return <option key={key} value={value}>{key}</option>;
                            })}
                            </Form.Control>
                        </Col>
                        
                        <Col md={6}>
                            <Row>
                            <Button variant="success mr-1" type="submit">
                                    Mostrar
                            </Button>
                            {this.state.show_result && <Button variant="primary mr-1" onClick={this.update_general}>
                                    Actualizar
                            </Button>}

                            {!this.state.state && <Button variant="danger mr-1" onClick={this.return}>
                                    Volver
                            </Button>}
                            </Row>
                        </Col>
                    </Form.Group>
                </Form>}
            </Col>
            <Col md={2}>
            <div class="d-flex justify-content-end">
                
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip}>
                    <button type= "button" className="button_image" onClick={this.search_ID}>
                        <img
                        src={require("./../static/search2.png")}
                        width="35"
                        height="35"
                        alt="React Bootstrap logo"
                        />
                    </button>
                </OverlayTrigger>

                {this.state.show_answers && <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip_answers}>
                            <button 
                                type= "button" 
                                className="button_image"
                                onClick={this.plotAnswers}>
                                <img src={require("./../static/signal.png")}
                                    width="40"
                                    height="40"
                                    alt="React Bootstrap logo"
                                />
                            </button>
                        </OverlayTrigger>
                }
                </div>
            </Col>
        </Row>}

        {/* Resultados por ID */}
        {this.state.state_search &&<Row>
            <Col md={12}>
                <Form noValidate validated={this.state.validated_ID} onSubmit={e => this.showResultsperID(e)}>
                    <Form.Group as={Row}>
                        <Form.Label column md={2}>
                            Doc. identidad:
                        </Form.Label>
                        <Col md={3}>
                            <Form.Control
                                required
                                name="id_search"
                                type="text"
                                value={this.state.id_search} 
                                onChange={this.handleChange}
                            />
                        </Col>                        
                        <Col md={5}>
                            <Row>
                            <Button variant="success mr-1" type="submit">
                                    Buscar
                            </Button>
                            <Button variant="danger mr-1" onClick={this.search_ID}>
                                    Volver
                            </Button>
                            </Row>
                        </Col>
                    </Form.Group>
                </Form>
            </Col>
        </Row>}

        
        {/* Resultados generales */}
        {this.state.show_result && !this.state.show_result_ID && this.state.state && <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>Doc. identidad</th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("speech")}>
                                Similitud voz (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_speech}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("face")}>
                                Similitud rostro (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_face}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("num_faces")}>
                                # Rostros
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_rostros}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("eval_udea")}>
                                EvalUdeA
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_evaludea}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.results.map(this.renderResults_general)}
                </tbody>
            </Table>
        </div>}

        {/* Resultados especificos */}
        {!this.state.state && !this.state.state_search && <div>
            <Table striped bordered>
                <thead>
                    <tr>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("date")}>
                                Fecha
                            </Button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("speech")}>
                                Similitud voz (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_speech}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("face")}>
                            Similitud rostro (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_face}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("num_faces")}>
                                # Rostros
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_rostros}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>Ver video</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.resultspersonal.map(this.renderResults_specific)}
                </tbody>
            </Table>
        </div>}
        <br />


        {/* Tabla Resultados por ID */}
        {this.state.show_result_ID &&<Table striped bordered>
            <thead>
                    <tr>
                        <th>Doc. identidad</th>
                        <th>Programa</th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("speech")}>
                                Similitud voz (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_speech}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("face")}>
                                Similitud rostro (%)
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_face}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("num_faces")}>
                                # Rostros
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_rostros}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                        <th>
                            <Button className="table_title" variant="link" onClick={() => this.order_results("eval_udea")}>
                                EvalUdeA
                            </Button>
                            <button type= "button" className="button_image" onClick={this.popup_evaludea}>
                                <img
                                src={require("./../static/question.png")}
                                width="20"
                                height="20"
                                alt="React Bootstrap logo"
                                />
                            </button>
                        </th>
                    </tr>
                </thead>
            <tbody>
                {this.state.results.map(this.renderTableID)}
            </tbody>
        </Table>}


        <center>
            {(!this.state.show_result && !this.state.show_result_ID && this.state.state) && <Button
                variant="primary mr-1" 
                onClick={this.export_general_results}>
                Exportar resultados generales
            </Button>}
        </center>

        <center>
            {(this.state.show_result && !this.state.show_result_ID && this.state.state && this.state.results.length>0) && <Button
                variant="primary mr-1" 
                onClick={this.export_results}>
                Exportar resultados
            </Button>}
        </center>
        
        <br />
        <br />

        {/* Popup ayuda voz */}
        <Modal show={this.state.show_popup_speech} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Similitud voz</Modal.Title>
            </Modal.Header>
              <Modal.Body>
              <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "green"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 70-100%.
                    </Col>
                </Row>  
                <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "yellow"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 50-70%.
                    </Col>
                </Row> 
                <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "red"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 0-50%.
                    </Col>
                </Row>   
                {!this.state.state&&<Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "grey"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        No se detecto voz en el video.
                    </Col>
                </Row>}
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Salir
                  </Button>
              </Modal.Footer>
        </Modal>

    
        {/* Popup ayuda rostro */}
        <Modal show={this.state.show_popup_face} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Similitud rostro</Modal.Title>
            </Modal.Header>
              <Modal.Body>
              <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "green"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 70-100%.
                    </Col>
                </Row>  
                <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "yellow"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 50-70%.
                    </Col>
                </Row> 
                <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "red"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Porcentaje de similitud entre 0-50%.
                    </Col>
                </Row>   
                {!this.state.state&&<Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "grey"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        No se detectaron rostros en el video.
                    </Col>
                </Row>}

                {!this.state.state&&<Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "darkorange"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Posible fraude por fotografía.
                    </Col>
                </Row>}  

                {!this.state.state&&<Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "deepskyblue"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Modelo no encontrado.
                    </Col>
                </Row>}  

                {!this.state.state&&<Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "black"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Video no encontrado o eliminado.
                    </Col>
                </Row>} 


              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Salir
                  </Button>
              </Modal.Footer>
        </Modal>

        {/* Popup ayuda # rostros */}
        <Modal show={this.state.show_popup_rostros} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter"># Rostros</Modal.Title>
            </Modal.Header>
              <Modal.Body>
              <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "green"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        Solo fue detectado 1 rostro en la escena.
                    </Col>
                </Row>  
                <Row>
                    <Col sm={1}>               
                        <div className="parent_popup">
                            <div className="circle" style={{background: "red"}}/>
                        </div> 
                    </Col>
                    <Col sm={11}>
                        No se detectaron rostros o se detecto mas de 1 rostro en la escena.
                    </Col>
                </Row>   
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Salir
                  </Button>
              </Modal.Footer>
        </Modal>


        {/* Popup ayuda evaludea */}
        <Modal show={this.state.show_popup_ayuda_evaludea} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">EvalUdeA</Modal.Title>
            </Modal.Header>
              <Modal.Body>
              <Row>
                    <Col sm={1}>               
                        <img
                        src={require("./../static/warning.png")}
                        width="20"
                        height="20"
                        alt="React Bootstrap logo"
                        className="d-inline-block align-top"
                        />
                    </Col>
                    <Col sm={11}>
                        Se detectaron actividades o softwares sospechosos.
                    </Col>
                </Row>  
                <Row>
                    <Col sm={1}>
                        <img
                        src={require("./../static/success_green.png")}
                        width="20"
                        height="20"
                        alt="React Bootstrap logo"
                        />               
                    </Col>
                    <Col sm={11}>
                        No se detectaron actividades o softwares sospechosos.
                    </Col>
                </Row>   
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="primary" onClick={this.handleClose}>
                      Salir
                  </Button>
              </Modal.Footer>
        </Modal>



        {/* Popup video */}
        <Modal show={this.state.show_video} onHide={this.handleCloseVideo} size="lg">
            {(this.state.text_video === "") &&<div className="embed-responsive embed-responsive-21by9">
                <iframe 
                    title="Embeds Page"
                    allowFullScreen
                    allow="autoplay"
                    className="embed-responsive-item"
                    controls="controls"
                    src={this.state.url_video}/>
            </div>}
            {!(this.state.text_video === "")&&<div className="modal_background">
                    <p style={{color:"white"}}>Cargando...</p>
            </div>}
            <center>
            <div style={{background: "black"}}>
                <Button variant="primary" size="sm" onClick={this.handleRegister}>
                        {this.state.show_register ? "Ocultar registro":"Mostrar registro"}
                </Button>
            </div>
            </center>

            <div style={{background: "black"}} />

            {this.state.show_register && <Row>
                <Col align="center" className="modal_background_photo">
                    <div>
                    <img src={this.state.url_foto}
                        width= "100%"
                        height= "100%"
                        alt="Cargando..."/>
                    </div>
                </Col>
                <Col align="center" className="modal_background_photo">
                    <img src={this.state.url_id}
                        width= "100%"
                        height= "100%"
                        alt="Cargando..."/>
                </Col>
                <Col className="modal_background">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe 
                            title="Embeds Page"
                            allowFullScreen
                            className="embed-responsive-item"
                            controls="controls"
                            src={this.state.url_video_register}/>
                    </div>
                </Col>
            </Row>}

        </Modal>

        {/* Popup leave invalid token */}
        <Modal show={this.state.leave_token} onHide={this.leave}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">No fue posible refrescar el token, por favor ingrese nuevamente</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.leave}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

        {/* Popup General */}
        <Modal show={this.state.show_popup} onHide={this.handleClosePopup}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClosePopup}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

        {/* Popup Plot Answers */}
        <Modal show={this.state.show_popup_answers} onHide={this.handleClose} size="lg">
        
            <Modal.Title id="contained-modal-title-vcenter">
                <center>
                    Dinámica de respuestas
                </center>
            </Modal.Title>
            <Modal.Body>
                <center>
                    <Plot
                        data={[
                        {
                            x: this.state.x_answers,
                            y: this.state.y_answers,
                            type: 'scatter',
                            mode: 'lines+markers',
                            marker: {color: 'green'},
                        },
                        ]}
                        layout={{
                            autosize: true,
                            xaxis: {title: 'Tiempo'},
                            yaxis: {title: 'Delta respuestas'}
                        }}
                    />
                <br />
                <br />
                <CSVLink 
                    style={{ textDecoration: 'none' }}
                    data={this.state.csv_report}
                    headers={this.state.headers}
                    filename={this.state.name_file_answers}>
                    <Button
                        variant="success mr-1">
                        Descargar dinámica
                    </Button>
                </CSVLink>
                </center>
            </Modal.Body>
        </Modal>


        {/* Popup EvalUdea */}
        <Modal show={this.state.show_popup_evaludea} onHide={this.handleClosePopup}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Doc. identidad {this.state.doc_identidad}
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ul>
                <li>Número de dispositivos externos detectados: {this.state.num_device>0?<b>{this.state.num_device}</b>:this.state.num_device}</li>
                <li>Número de intentos de pantalla duplicada: {this.state.num_screen>0?<b>{this.state.num_screen}</b>:this.state.num_screen}</li>
                <li>Número de softwares sospechosos detectados: {this.state.num_software>0?<b>{this.state.num_software}</b>:this.state.num_software}</li>
            </ul>  
            {this.state.show_table_software && <Table striped bordered>
                <thead>
                        <tr>
                            <th>Fecha</th>
                            <th>Software</th>
                            <th>Capturas</th>
                        </tr>
                    </thead>
                <tbody>
                    {this.state.list_software.map(this.renderTableEval)}
                </tbody>
            </Table>}

            <center>
            <Button
                variant="success mr-1" 
                size="sm"
                onClick={this.export_results_evaludea}>
                Descargar
            </Button>
            </center>

            </Modal.Body>


            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClosePopup}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

        {/* Popup screen evaludea*/}
        <Modal size="lg" show={this.state.show_screenshot} onHide={this.handleClosePopupScreenshot}>
            <img src={this.state.url_screenshot}
                width= "100%"
                height= "100%"
                alt="Cargando..."/>
        </Modal>


    </Container>
    )
}

}


export default withRouter(ResultsExamAdm)