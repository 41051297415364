import React from 'react'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import './../style.css';
import {Form, Button, Container, Modal} from 'react-bootstrap'

var static_token = "zM8dnkI4YF3YCwV5RUPeQLvss6M9a6VB8ASADcQexIiLiHWRDNa7czPpZFS6UzS2BeAKvAvq"

class LoginAdminAdmission extends React.Component {

  constructor(props){
      super(props)
      this.state = {
        id_admission:{},
        email:"",
        pass:"",
        code_app:"",
        id_admission_user:"",
        show_popup:false,
        show_popup_recover:false,
        popup:"",
        validate: true,
        validated: false,

      }
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
      const config = {headers: {'Token-Security':static_token}}
      axios.get('https://biometria-api.udea.edu.co/admissionExam/Results/listAdmission',
      config) // <----- CAMBIAR POR URL SEMESTRE
      .then(res => {
        console.log(res.data)
        let list = res.data
        let dict = {}; // Convert list to dict
        for (let i=0; i< list.length; i++ ) {
                dict[list[i]] = list[i];
        }
        console.log(dict);
        this.setState({id_admission:dict})
      })
    }

  handleChange=(event)=> {
      const {name, value} = event.target
      this.setState({[name]: value})
  }

  SaveToken = token => {
    if (token) {
    //applying token
      axios.defaults.headers.common['Authorization'] = token;
      console.log("--------Token GUARDADO-----")
    } 
    else{
      //deleting the token from header
      delete axios.defaults.headers.common['Authorization'];
      console.log("--------Token ELIMINADO-----")

    }
   }

   handleClose = () => {
    this.setState({show_popup:false, show_popup_recover:false});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        console.log("invalid form");
    }
    this.setState({validated: true})

    if(form.checkValidity()){
      console.log("Correo:",this.state.email)
      console.log("Pass:",this.state.pass)
      console.log("Admission:",this.state.id_admission_user)

      
      var body = new FormData();
      body.append("email",this.state.email)
      body.append("password",this.state.pass)
      body.append("admission",this.state.id_admission_user)

      const config = {
        headers: {'Content-Type': 'multipart/form-dat',
                'Token-Security':static_token}
      };

      axios.post(
        "https://biometria-api.udea.edu.co/admissionExam/general/login",
        body,
        config).then(res => { 
          console.log(res.data)
          if(res.data["passwordValid"] && res.data["userExist"]){
            localStorage.clear();
            localStorage.setItem('email_user_admission', this.state.email);
            localStorage.setItem('password_admission', this.state.pass);
            localStorage.setItem('tokenSecurity_admission', res.data["tokenSecurity"]);
            localStorage.setItem('id_admission', this.state.id_admission_user);
            this.props.history.push({
              pathname:'/admision/admin/results'
            })
          }
          else if (!res.data["passwordValid"] && res.data["userExist"]){
            this.setState({popup:"Contraseña invalida", show_popup:true})
          }
          else if (!res.data["userExist"]){
            this.setState({popup:"Usuario no existe", show_popup:true})
          }
          else {
            this.setState({popup:"Por favor contacte el adminstrador", show_popup:true})
          }
        },(err)=>{
          console.log("Tenemos un error", err)
        })
    }
  }

  
  render() {
    return (
      <Container>
        <div className="cinta-superior">
            <img
            src={require("./../static/logoudea.svg")}
            width="320"
            height="100"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
            />
        </div>
        <hr />
        <div className="login-page">
          <div className="form">
            <p style={{fontSize: "25px"}}>Iniciar sesión</p>
            <Form noValidate validated={this.state.validated} onSubmit={e => this.handleSubmit(e)}>
              <Form.Group>
                <Form.Control 
                    required
                    as="select"  
                    name="id_admission_user" 
                    value={this.state.id_admission_user}
                    onChange={this.handleChange}>
                    <option value="" disabled>ID de admisión...</option>
                    {Object.entries(this.state.id_admission).map( ([key, value]) => {
                        return <option key={key} value={value}>{key}</option>;
                    })}
                </Form.Control>
              </Form.Group>
  
              <Form.Group>
                <Form.Control 
                  required
                  name="email"
                  type="email"
                  value={this.state.email} 
                  placeholder="Correo electrónico"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group>
                <Form.Control 
                  required
                  name="pass"
                  type="password"
                  value={this.state.pass} 
                  placeholder="Contraseña"
                  onChange={this.handleChange}
                />
                <Form.Control.Feedback type="invalid">Debe contener 8 caracteres</Form.Control.Feedback>
              </Form.Group>

              <Button
                variant="success mr-1"
                type="submit">
                Ingresar
              </Button>
            </Form>

          </div>
        </div>

        {/* Mensaje final */}
        <Modal show={this.state.show_popup} onHide={this.handleClose}>
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{this.state.popup}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
                Salir
            </Button>
            </Modal.Footer>
        </Modal>

      </Container>
    )
  }
}


export default withRouter(LoginAdminAdmission)